import React, { useCallback, useRef, useEffect, useState } from "react";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";
import { Autorenew, CloudDone } from "@mui/icons-material";
import Touchpad from "./Touchpad";
import Loading from "../Loading";
import {
  useVisit,
  useUpdateVisit,
  useCompleteVisit,
} from "../../queries/visits";
import { useParams } from "react-router-dom";
import { useAllChiropractors } from "../../queries/chiropractors";
import VisitNote from "./VisitNote";
import { useSetAtom } from "jotai";
import { visitNoteAtom } from "../../store";
import { useUserChiropractor } from "../../queries/chiropractors";

export default function Visit() {
  const theme = useTheme();
  const styles = {
    saveStatus: {
      fontSize: theme.typography.body2.fontSize,
      "& svg": {
        fontSize: "inherit",
        verticalAlign: "middle",
      },
    },
  };

  const { visitId } = useParams();
  const {
    data: visit,
    isLoading: visitLoading,
    isFetching: visitFetching,
  } = useVisit(visitId);
  const { data: chiropractors } = useAllChiropractors();
  const { data: userChiropractor } = useUserChiropractor();
  const { mutate: updateVisit, isLoading: visitUpdating } = useUpdateVisit();
  const { mutate: completeVisit, isLoading: visitCompleting } =
    useCompleteVisit(visit?.relationships?.location?.data?.id);
  const [draftVisit, setDraftVisit] = useState(null);
  const setVisitNote = useSetAtom(visitNoteAtom);
  const [visitNoteHistory, setVisitNoteHistory] = useState({
    list: [],
    index: -1,
  });
  const noteRef = useRef();

  const undoVisitNote = () => {
    setVisitNoteHistory((prevHistory) => {
      const newHistory = {
        list: prevHistory.list,
        index: prevHistory.index - 1,
      };
      setVisitNote(newHistory.list[newHistory.index]);
      delayedSaveNote(newHistory.list[newHistory.index]);
      return newHistory;
    });
  };

  const redoVisitNote = () => {
    setVisitNoteHistory((prevHistory) => {
      const newHistory = { ...prevHistory, index: prevHistory.index + 1 };
      setVisitNote(newHistory.list[newHistory.index]);
      delayedSaveNote(newHistory.list[newHistory.index]);
      return newHistory;
    });
  };

  const saveDraftVisit = async (complete = false) => {
    // do not attempt to save/complete unless noteRef.current is set
    if (!noteRef?.current) return;

    // include the current note in the draft
    const newDraft = _.cloneDeep(draftVisit);
    newDraft.attributes.visitNote = noteRef.current.value;
    await updateVisit(newDraft);
    if (complete) {
      await completeVisit(newDraft.id);
    }
  };

  const handleSwitchVisit = () => {
    setVisitNote(visit.attributes.visitNote);

    // enable editing by default if visit is not completed
    enableEditing(!visit.attributes.timeCompleted);
  };

  const enableEditing = (enable) => {
    if (enable) {
      const newDraft = _.cloneDeep(visit);
      setDraftVisit(newDraft);
      setVisitNoteHistory({ list: [visit.attributes.visitNote], index: 0 });
    } else {
      setDraftVisit(null);
      setVisitNoteHistory(null);
    }
  };

  const addUndoCheckpoint = (newNote) => {
    setVisitNoteHistory((prevHistory) => {
      // don't update history if it's the same
      if (prevHistory.list[prevHistory.index] === newNote) return prevHistory;

      const newHistory = {
        list: [...prevHistory.list.splice(0, prevHistory.index + 1), newNote],
        index: prevHistory.index + 1,
      };
      return newHistory;
    });
  };

  const saveNote = (newNote) => {
    // make sure fields are in sync before save
    addUndoCheckpoint(newNote);
    saveDraftVisit(false); // save, but don't complete
  };

  // save note after 1 second delay
  const delayedSaveNote = useCallback(
    _.debounce((newNote) => {
      saveNote(newNote);
    }, 1000),
    [draftVisit]
  );

  useEffect(() => {
    if (visit?.id) handleSwitchVisit();
  }, [visit?.id]);

  // set cursor position if editing enabled
  useEffect(() => {
    if (!!draftVisit?.id) {
      noteRef.current?.focus();
      noteRef.current?.setSelectionRange(
        draftVisit?.attributes?.visitNote?.length || 0,
        draftVisit?.attributes?.visitNote?.length || 0
      );
    }
  }, [draftVisit?.id]);

  if (!visit || visitLoading || visitCompleting) return <Loading size={80} />;

  return (
    <>
      <Box flex="0 0 auto" style={{ padding: 8, textAlign: "center" }}>
        {visit?.attributes.appointmentTime
          ? new Date(visit.attributes.appointmentTime).toLocaleString(
              {},
              {
                month: "numeric",
                day: "numeric",
                year: "numeric",
                timeZone: "UTC",
              }
            ) +
            " @ " +
            new Date(visit.attributes.appointmentTime)
              .toLocaleString(
                {},
                { hour: "numeric", minute: "2-digit", timeZone: "UTC" }
              )
              .toLowerCase()
          : "Walk-in"}
        {" for "}
        {chiropractors?.find(
          (chiro) =>
            chiro.id === visit?.relationships?.prefChiropractor?.data?.id
        )?.attributes.displayName || "Any Chiropractor"}
      </Box>
      <Box alignItems="center" style={{ width: "100%", padding: 8 }}>
        <Typography sx={styles.saveStatus}>
          {visitFetching || visitUpdating ? (
            <>
              <Autorenew /> Updating...
            </>
          ) : (
            <>
              <CloudDone /> Saved.
            </>
          )}
        </Typography>
        <VisitNote
          noteRef={noteRef}
          disabled={!draftVisit}
          onChange={delayedSaveNote}
        />
      </Box>
      <Box flex="0 1 100%" style={{ padding: 8, overflowY: "hidden" }}>
        {draftVisit ? (
          <Touchpad
            noteRef={noteRef}
            addNote={(newNote) => {
              setVisitNote(newNote);
              addUndoCheckpoint(newNote);
              delayedSaveNote(newNote);
            }}
            undo={undoVisitNote}
            redo={redoVisitNote}
            canUndo={visitNoteHistory?.index > 0}
            canRedo={
              visitNoteHistory?.index < visitNoteHistory?.list.length - 1
            }
            completeVisit={
              visit.attributes.timeCompleted
                ? false
                : () => saveDraftVisit(true)
            }
          />
        ) : (
          <>
            <Typography variant="h6" align="center">
              Editing Disabled
            </Typography>
            <Typography align="center">
              This visit is already completed.
              <br />
              {visit.relationships?.chiropractor?.data?.id ===
                userChiropractor.id && (
                <Button align="center" onClick={() => enableEditing(true)}>
                  Edit Visit
                </Button>
              )}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
}

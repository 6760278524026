import React from "react";
import { useTable } from "react-table";
import { Bar } from "react-chartjs-2";
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useVisitReportData } from "../../queries/reports";
import { useAllChiropractors } from "../../queries/chiropractors";
import Loading from "../Loading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const styles = {
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: 440,
  },
};

const columns = [
  { Header: "Chiropractor", accessor: "chiroName" },
  { Header: "Visits", accessor: "visitCount" },
  { Header: "Typical Wait (m:ss)", accessor: "waitIQRString" },
  { Header: "Avg Wait (m:ss)" },
  { Header: "Typical Active (m:ss)", accessor: "activeIQRString" },
  { Header: "Avg Active (m:ss)" },
];

export default function VisitsEfficiencyReport() {
  const { data: chiropractors, isLoading: chirosLoading } =
    useAllChiropractors();
  const { data: reportData, isLoading: reportLoading } = useVisitReportData({
    filter: { startDate: "2022-10-01", endDate: "2022-10-31" },
  });
  const flattenedData = reportData?.map((item) => {
    const chiro = chiropractors.find(
      (chiro) => chiro.id == item.attributes.chiropractorId
    );
    return { ...item.attributes, chiroName: chiro?.attributes.displayName };
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: flattenedData || [] });

  if (chirosLoading || reportLoading)
    return <Loading caption="Generating report..." />;

  /*const data = {
    labels: Object.keys(reportData.attributes.waitTimeCounts),
    datasets: [
      {
        label: "Minutes Waiting / Visit",
        data: reportData.attributes.waitTimeCounts,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Minutes Active / Visit",
        data: reportData.attributes.activeTimeCounts,
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgba(255, 159, 64, 1)",
        borderWidth: 1,
      },
    ],
  };*/

  return (
    <div>
      <Paper sx={styles.container}>
        <TableContainer>
          <Table sx={styles.table} {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {/*<Bar data={data} />*/}
    </div>
  );
}

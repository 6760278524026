import { getData, generateQueryString } from ".";
import { useQuery } from "react-query";
import { useAtomValue } from "jotai/utils";
import { tokenAtom } from "../store";

export const useVisitReportData = (options) => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["reports", "chiropractorVisits", options],
    async () => {
      const queryString = generateQueryString(options);
      const { data: reportData } = await getData(
        `/api/reports/chiropractor-visits${queryString}`,
        token
      );
      return reportData;
    },
    { enabled: !!token && !!options }
  );
};

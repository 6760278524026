import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  useMediaQuery,
  Box,
  Drawer,
  ButtonBase,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Typography,
  Button,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { DateTime } from "luxon";
//import useWindowSize from "react-use/lib/useWindowSize";
import StickyNotes from "./StickyNotes";
import Waitlist from "./Waitlist";
import ClientVisits from "./ClientVisits";
//import UploadAvatarDialog from "../UploadAvatarDialog";
import Loading from "../Loading";
import BirthdayConfetti from "../BirthdayConfetti";
import Visit from "./Visit";

import { useClient } from "../../queries/clients";
import { useCreateVisit, useVisit } from "../../queries/visits";
import { useParams } from "react-router-dom";
import { useAtomValue, useSetAtom } from "jotai";
import { currentClientAtom, currentLocationAtom } from "../../store";
import { useUserChiropractor } from "../../queries/chiropractors";

export default function Ehr() {
  const theme = useTheme();
  const styles = {
    rightColumnExpand: {
      position: "fixed",
      right: 0,
      bottom: "50%",
      width: 20,
      height: 40,
      borderRadius: "20px 0 0 20px",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  };

  const { visitId, clientId } = useParams();

  const { data: visit } = useVisit(visitId);
  const { data: client, isLoading: clientLoading } = useClient(
    visit?.relationships?.client?.data?.id || clientId
  );
  const { data: userChiropractor } = useUserChiropractor();
  const currentLocation = useAtomValue(currentLocationAtom);
  const { mutate: createVisit } = useCreateVisit();
  const isFullScreen = useMediaQuery("(min-width:1200px)");
  const [rightColumnVisible, setRightColumnVisible] = useState(isFullScreen);

  const setCurrentClient = useSetAtom(currentClientAtom);
  useEffect(() => {
    if (!!client) {
      setCurrentClient(client);
    }
    return () => {
      setCurrentClient(null);
    };
  }, [client, setCurrentClient]);

  const createNewVisit = () => {
    const newVisit = {
      type: "visits",
      attributes: {
        visitDate: DateTime.now().toSQLDate(),
        visitNote: "",
        timeCheckedIn: DateTime.now().toSQL({ includeOffset: false }),
        timeCalled: DateTime.now().toSQL({ includeOffset: false }),
      },
      relationships: {
        client: { data: { id: client.id, type: "clients" } },
        location: { data: { id: currentLocation.id, type: "locations" } },
        chiropractor: {
          data: { id: userChiropractor.id, type: "chiropractors" },
        },
      },
    };
    createVisit(newVisit);
  };

  //const [uploadDialog, setUploadDialog] = useState(false);

  /*const handlePhotoChange = (photoVersion) => {
    client.photoVersion = photoVersion;
    setVisit(visit);
  };*/

  if (!client || clientLoading) return <Loading size={80} />;

  const renderRightColumn = () => {
    if (isFullScreen) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          flex="1 0 400px"
          style={{ minWidth: 400, minHeight: 0 }}
        >
          <Box style={{ flex: "1 1 50%", overflowY: "auto" }}>
            <Waitlist currentVisit={visit} />
          </Box>
          <ClientVisits clientId={client?.id} currentVisitId={visitId} />
        </Box>
      );
    } else {
      return (
        <Box>
          <Drawer
            anchor="right"
            open={rightColumnVisible}
            onClose={() => setRightColumnVisible(false)}
          >
            <Box display="flex" flexDirection="column" flex="1 0 400px">
              <Waitlist />
              <ClientVisits clientId={client?.id} currentVisit={visit} />
            </Box>
          </Drawer>
          <ButtonBase
            onClick={() => setRightColumnVisible(true)}
            sx={styles.rightColumnExpand}
          >
            <ChevronLeft />
          </ButtonBase>
        </Box>
      );
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        style={{
          height: "calc(100% - 52px)",
          position: "absolute",
          width: "100%",
        }}
      >
        <Box display="flex" flexDirection="column" flex="0 0 200px">
          <Box flex="0 1 auto">
            <Avatar
              src={client?.attributes.photoUrl ?? "//:0"}
              sx={{
                width: 160,
                height: 160,
                margin: "auto",
                marginTop: 1,
              }}
            >
              {(client?.attributes.nickname?.charAt(0) ||
                client?.attributes.firstName?.charAt(0)) +
                client?.attributes.lastName?.charAt(0)}
            </Avatar>
            <List dense>
              <ListItem>
                <ListItemText
                  primary={
                    `${client?.attributes.firstName?.trim()} ` +
                    (!!client?.attributes.nickname?.trim() &&
                    client?.attributes.nickname?.trim() !==
                      client?.attributes.firstName
                      ? `"${client?.attributes.nickname?.trim()}" `
                      : "") +
                    client?.attributes.lastName
                  }
                  primaryTypographyProps={{ variant: "h6" }}
                  secondary={
                    client?.attributes.birthDate
                      ? DateTime.fromSQL(
                          client.attributes.birthDate
                        ).toLocaleString() +
                        " (" +
                        Math.floor(
                          DateTime.fromSQL(client.attributes.birthDate).diffNow(
                            "years"
                          ).years * -1
                        ) +
                        ")"
                      : "Need DOB"
                  }
                />
              </ListItem>
              <Typography variant="body2" marginX={2}>
                Gender: {client.attributes.gender}
                <br />
                Status:{" "}
                {client.attributes.membershipStatus === "Active" ? (
                  <span>Member</span>
                ) : (
                  <span style={{ color: "red" }}>Non-member</span>
                )}
              </Typography>
            </List>
          </Box>

          <StickyNotes clientId={client?.id} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="left"
          flex="1 0 600px"
        >
          {!!visit ? (
            <Visit />
          ) : (
            <>
              <Typography align="center" variant="h6" margin={2}>
                No visit selected
              </Typography>
              <Typography align="center">
                <Button onClick={createNewVisit}>Start New Visit</Button>
              </Typography>
            </>
          )}
        </Box>
        {renderRightColumn()}
      </Box>
      {/* ********
        <UploadAvatarDialog
        open={uploadDialog}
        setOpen={setUploadDialog}
        clientId={client?.id}
        onPhotoChange={handlePhotoChange}
      />
          */}
      <BirthdayConfetti birthDate={client?.attributes?.birthDate} />
    </>
  );
}

import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useCreateRole, useRoles, useUpdateRole } from "../../queries/roles";
import Loading from "../Loading";
export default function Roles() {
  const { data: roles } = useRoles();
  const { mutate: createRole } = useCreateRole();
  const { mutate: updateRole } = useUpdateRole();
  const [draftRole, setDraftRole] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const addRole = () => {
    setDialogOpen(true);
    setDraftRole({ type: "roles", attributes: { name: "" } });
  };

  const editRole = (role) => {
    setDraftRole(role);
    setDialogOpen(true);
  };

  const handleChangeRoleName = (e) => {
    setDraftRole((prevDraft) => ({
      ...prevDraft,
      attributes: { name: e.target.value },
    }));
  };

  if (!roles) return <Loading size={80} />;

  return (
    <Box>
      <Typography>
        <Button onClick={addRole}>Add New Role</Button>
      </Typography>
      <List disablePadding>
        {roles.map((role) => (
          <ListItem button onClick={() => editRole(role)} key={role.id}>
            <ListItemText primary={role.attributes.name} />
          </ListItem>
        ))}
      </List>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setDraftRole(null);
        }}
      >
        <DialogTitle>{draftRole?.id ? "Edit Role" : "Add Role"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Role Name"
            value={draftRole?.attributes?.name}
            onChange={handleChangeRoleName}
            sx={{ m: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button
            variant="outlined"
            onClick={() => {
              draftRole?.id ? updateRole(draftRole) : createRole(draftRole);
              setDialogOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

import { useAtom } from "jotai";
import { errorAtom } from "../../store";
import { Button, Typography } from "@mui/material";
import { useQueryClient } from "react-query";

export default function KioskError() {
  const [error, setError] = useAtom(errorAtom);
  const queryClient = useQueryClient();

  const retryAllFailedQueries = () => {
    // Get all queries from the query cache
    const queries = queryClient.getQueryCache().findAll();

    // Iterate over the queries and refetch those in an error state
    queries.forEach((query) => {
      if (query.state.status === "error") {
        queryClient.prefetchQuery(query.queryKey, {
          force: true, // Force the refetch even if the data is stale or not stale
        });
      }
    });

    setError(null);
  };

  if (error) {
    return (
      <>
        <Typography variant="h5">
          Oh no! It looks like something isn't working.
        </Typography>
        <Typography>
          This can happen when our internet connection goes down.
          <br />
          Technology, right?
        </Typography>
        <Button
          sx={{ m: 4 }}
          onClick={retryAllFailedQueries}
          variant="outlined"
        >
          Try Again
        </Button>
      </>
    );
  }
}

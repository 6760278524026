import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import LocationIcon from "@mui/icons-material/LocationOn";

import { useUpdateAtom } from "jotai/utils";
import { currentLocationAtom } from "../../store";
import { usePublicLocations } from "../../queries/locations";

export default function SelectLocation() {
  const { data: locations } = usePublicLocations();
  const setCurrentLocation = useUpdateAtom(currentLocationAtom);

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ display: "inline-block", margin: "auto" }}>
        <h3 style={{ fontWeight: 400 }}>Please select a location:</h3>
        <List>
          {!locations ? (
            <CircularProgress />
          ) : (
            locations.map((location) => (
              <ListItem
                button
                key={location.id}
                onClick={() => setCurrentLocation(location)}
              >
                <ListItemIcon>
                  <LocationIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={location.attributes.name}
                  secondary={
                    location.attributes.streetAddress +
                    ", " +
                    location.attributes.city +
                    ", " +
                    location.attributes.state +
                    " " +
                    location.attributes.zipCode
                  }
                />
              </ListItem>
            ))
          )}
        </List>
      </div>
    </div>
  );
}

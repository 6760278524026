import { memo, useEffect } from "react";
import { TextField } from "@mui/material";
import { useAtom } from "jotai";
import { visitNoteAtom } from "../../store";

const VisitNote = memo(({ noteRef, disabled, onChange }) => {
  const [visitNote, setVisitNote] = useAtom(visitNoteAtom);

  const handleVisitNoteTyping = (e) => {
    const newNote = e.target.value;
    setVisitNote(newNote);
    onChange(newNote);
  };

  useEffect(() => {
    return () => {
      setVisitNote(""); // clear visit note on unmount
    };
  }, []);

  return (
    <TextField
      name="visitNote"
      multiline
      rows={5}
      style={{ width: "100%" }}
      inputRef={noteRef}
      variant="outlined"
      onChange={handleVisitNoteTyping}
      value={visitNote}
      disabled={disabled}
      onBlur={() => {
        setTimeout(() => {
          if (noteRef?.current && document.activeElement !== noteRef.current) {
            noteRef.current.setSelectionRange(
              noteRef.current.value.length,
              noteRef.current.value.length
            );
          }
        }, 100);
      }}
    />
  );
});

export default VisitNote;

import { getData } from ".";
import { useQuery } from "react-query";

export const useAppSettings = () => {
  return useQuery(["appSettings"], async () => {
    const { data: settings } = await getData("/api/settings/public-settings");

    // convert settings into single object for ease of use
    const unstructuredSettings = {};
    settings.forEach((setting) => {
      unstructuredSettings[setting.attributes.name] = setting.attributes.value;
    });
    return unstructuredSettings;
  });
};

import { getData, patchData } from ".";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSetAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import { tokenAtom, alertAtom } from "../store";

export const useSubscription = () => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["subscription"],
    async () => {
      const { data: subscription } = await getData(
        `/api/mbo/subscription`,
        token
      );
      return subscription;
    },
    { enabled: !!token }
  );
};

export const useActivateSubscription = () => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();
  const setAlert = useSetAtom(alertAtom);

  return useMutation(
    (activate) => {
      return patchData(
        `/api/mbo/subscription/${activate ? "activate" : "deactivate"}`,
        token
      );
    },
    {
      enabled: !!token,
      onSettled: async ({ data: updatedSubscription, errors }) => {
        // handle errors
        if (!!errors?.[0]) {
          setAlert({ title: errors[0], content: errors[0].detail });
          return;
        } else if (!updatedSubscription?.id) {
          setAlert({ content: "Error updating subscription." });
          return;
        }

        // update subscription
        queryClient.setQueryData(["subscription"], updatedSubscription);
      },
    }
  );
};

export const useMboEvents = () => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["mboEvents"],
    async () => {
      const { data: events } = await getData(
        `/api/mbo/unprocessed-events`,
        token
      );
      return events;
    },
    { enabled: !!token }
  );
};

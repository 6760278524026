import { useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Loading from "../Loading";
import { useUsers, useCreateUser, useUpdateUser } from "../../queries/users";
import { useRoles } from "../../queries/roles";
import { useAllChiropractors } from "../../queries/chiropractors";

export default function Users() {
  const { data: users } = useUsers();
  const { data: roles } = useRoles();
  const { data: chiropractors } = useAllChiropractors();
  const { mutate: createUser } = useCreateUser();
  const { mutate: updateUser } = useUpdateUser();
  const [draftUser, setDraftUser] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [chiropractorAutocomplete, setChiropractorAutocomplete] = useState("");
  const [userRoles, setUserRoles] = useState("");

  const addUser = () => {
    setDraftUser({
      type: "users",
      attributes: {
        firstName: "",
        lastName: "",
        email: "",
      },
      relationships: {
        chiropractor: { data: { id: null } },
      },
    });
    setDialogOpen(true);
  };

  const editUser = (user) => {
    setDraftUser(user);
    setDialogOpen(true);
  };

  const handleDraftAttributeChange = (e) => {
    setDraftUser((prevDraft) => ({
      ...prevDraft,
      attributes: {
        ...prevDraft.attributes,
        [e.target.name]: e.target.value,
      },
    }));
  };

  const handleToggleActive = (e) => {
    setDraftUser((prevDraft) => ({
      ...prevDraft,
      attributes: { ...prevDraft.attributes, isActive: e.target.checked },
    }));
  };

  const changeDraftUserChiropractor = (chiroId) => {
    setDraftUser((prevDraft) => ({
      ...prevDraft,
      relationships: {
        ...prevDraft.relationships,
        chiropractor: { data: { id: chiroId, type: "chiropractors" } },
      },
    }));
  };

  const changeDraftUserRoles = (roles) => {
    const rolesData = roles.map((role) => ({ id: role.id, type: "roles" }));

    setDraftUser((prevDraft) => ({
      ...prevDraft,
      relationships: {
        ...prevDraft.relationships,
        roles: { data: rolesData },
      },
    }));
  };

  const removeUserRole = (user, roleId) => {
    const roleIndex = user.relationships?.roles?.data?.findIndex(
      (role) => role.id === roleId
    );
    if (roleIndex > -1) {
      user.relationships.roles.data.splice(roleIndex, 1);
      updateUser(user);
    }
  };

  if (!users || !roles || !chiropractors) return <Loading size={80} />;

  const filteredUsers = showInactive
    ? users
    : users.filter((u) => u.attributes.isActive === true);

  const activeChiropractors = chiropractors.filter(
    (chiro) => !!chiro.attributes.active
  );

  return (
    <Box>
      <Typography>
        <Button onClick={addUser}>Add New User</Button>
        <FormControlLabel
          control={
            <Switch
              checked={!!showInactive}
              onChange={() => setShowInactive(!showInactive)}
              name="hideCalled"
            />
          }
          label="Show inactive users"
          sx={{ float: "right" }}
        />
      </Typography>

      <List dense>
        {filteredUsers.map((user) => (
          <ListItem
            button
            onClick={() => editUser(user)}
            key={user.id}
            sx={{ opacity: user.attributes.isActive ? 1 : 0.5 }}
          >
            <ListItemAvatar>
              <Avatar>
                {user.attributes.firstName?.charAt(0) +
                  user.attributes.lastName?.charAt(0)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${user.attributes.firstName} ${user.attributes.lastName}`}
              secondary={user.attributes.email}
            />
            <ListItemSecondaryAction>
              {user.relationships?.roles?.data?.map((role) => (
                <Chip
                  onDelete={() => removeUserRole(user, role.id)}
                  key={role.id}
                  label={roles?.find((r) => r?.id === role?.id).attributes.name}
                  sx={{ m: 1 }}
                />
              ))}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setDraftUser(null);
        }}
      >
        <DialogTitle>{draftUser?.id ? "Edit User" : "Add User"}</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!draftUser?.attributes?.isActive}
                onChange={handleToggleActive}
              />
            }
            label="Active"
            sx={{ mx: 1 }}
          />
          <br />
          <TextField
            label="First Name"
            sx={{ m: 1, width: "50%", maxWidth: 200 }}
            name="firstName"
            value={draftUser?.attributes.firstName || ""}
            onChange={handleDraftAttributeChange}
          />
          <TextField
            label="Last Name"
            sx={{ m: 1, width: "50%", maxWidth: 200 }}
            name="lastName"
            value={draftUser?.attributes.lastName || ""}
            onChange={handleDraftAttributeChange}
          />
          <br />
          <TextField
            label="Email"
            sx={{ m: 1, width: "100%", maxWidth: 416 }}
            name="email"
            value={draftUser?.attributes.email || ""}
            onChange={handleDraftAttributeChange}
          />
          <br />
          <Autocomplete
            sx={{ width: "100%", maxWidth: 416, m: 1 }}
            options={activeChiropractors}
            autoHighlight
            getOptionLabel={(chiro) => chiro?.attributes?.fullName || ""}
            renderOption={(props, chiro) => (
              <ListItem {...props}>
                <ListItemAvatar>
                  <Avatar src={chiro.attributes.photoUrl || "//:0"} />
                </ListItemAvatar>
                {chiro.attributes.displayName} ({chiro.attributes.fullName})
              </ListItem>
            )}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            value={
              activeChiropractors.find(
                (chiro) =>
                  chiro.id === draftUser?.relationships?.chiropractor?.data?.id
              ) || null
            }
            onChange={(_e, chiro) => {
              changeDraftUserChiropractor(chiro?.id || null);
            }}
            inputValue={chiropractorAutocomplete}
            onInputChange={(e, input) => {
              setChiropractorAutocomplete(input);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Chiropractor"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Autocomplete
            sx={{ width: "100%", maxWidth: 416, m: 1, mt: 2 }}
            freeSolo
            multiple
            options={roles}
            disableCloseOnSelect
            getOptionLabel={(option) => option.attributes.name}
            renderOption={(props, optionRole, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {optionRole.attributes.name}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            value={
              roles.filter((role) =>
                draftUser?.relationships?.roles?.data?.some(
                  (userRole) => userRole?.id === role?.id
                )
              ) || []
            }
            onChange={(_e, roles) => {
              changeDraftUserRoles(roles || []);
            }}
            inputValue={userRoles}
            onInputChange={(e, input) => {
              setUserRoles(input);
            }}
            renderInput={(params) => <TextField {...params} label="Roles" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button
            variant="outlined"
            onClick={() => {
              draftUser?.id ? updateUser(draftUser) : createUser(draftUser);
              setDialogOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

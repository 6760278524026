import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";

export default function ConfirmDialog({
  title,
  children,
  open,
  setOpen,
  onConfirm,
  confirmText,
}) {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          onClick={() => {
            onConfirm();
            setOpen(false);
          }}
          color="primary"
          variant="contained"
        >
          {confirmText || "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

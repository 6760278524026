import { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { useAtomValue } from "jotai";
import { currentLocationAtom } from "../../store";
import { useAppSettings } from "../../queries/settings";

function Keypad({ onSearch }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const { data: appSettings } = useAppSettings();
  const currentLocation = useAtomValue(currentLocationAtom);
  const areaCode = currentLocation?.attributes?.phone?.substring(0, 3) || "";

  const buttonSize = 96;
  const buttonMargin = 8;

  const handleClick = (num) => {
    // don't allow 0 or 1 as first digit
    if (phoneNumber.length === 0 && [0, 1].includes(num)) return;
    // max 10 digits
    if (phoneNumber.length === 10) return;

    setPhoneNumber((prev) => prev + num);
  };

  const handleBackspace = () => {
    setPhoneNumber((prev) => prev.slice(0, -1));
  };

  const handleSearch = () => {
    const fullPhoneNumber =
      phoneNumber.length === 7 ? areaCode + phoneNumber : phoneNumber;
    if (fullPhoneNumber.length === 10) {
      onSearch(fullPhoneNumber);
    }
  };

  const formattedPhoneNumber = () => {
    if (phoneNumber.length < 4) return phoneNumber;

    if (phoneNumber.length < 8)
      return phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3);

    return (
      "(" +
      phoneNumber.substring(0, 3) +
      ") " +
      phoneNumber.substring(3, 6) +
      "-" +
      phoneNumber.substring(6)
    );
  };

  return (
    <>
      <Typography variant="h4">
        Welcome to {appSettings?.businessName} in{" "}
        {currentLocation?.attributes?.city}
      </Typography>
      <Typography sx={{ m: 3 }}>
        Please scan your membership card or enter your phone number ({areaCode}{" "}
        is optional).
      </Typography>
      <div style={{ width: (buttonSize + buttonMargin * 2) * 3 }}>
        <TextField
          value={formattedPhoneNumber()}
          type="tel"
          readOnly
          InputProps={{ style: { fontSize: 32 } }}
          sx={{ width: "100%" }}
        />
        <Grid container>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, "back", 0, "search"].map(
            (num, index) => (
              <Grid key={index} item xs={4}>
                <Button
                  sx={{
                    height: buttonSize,
                    width: buttonSize,
                    fontSize:
                      num === "search" ? buttonSize / 5 : buttonSize / 2,
                  }}
                  color={num === "search" ? "primary" : "secondary"}
                  style={{ marginTop: buttonMargin * 2 }}
                  variant="contained"
                  onClick={
                    num === "back"
                      ? handleBackspace
                      : num === "search"
                      ? handleSearch
                      : () => handleClick(num)
                  }
                  disabled={
                    (num === "search" &&
                      phoneNumber.length !== 7 &&
                      phoneNumber.length !== 10) ||
                    (num === "back" && phoneNumber.length === 0)
                  }
                >
                  {num !== "back" && num !== "search" && num}
                  {num === "back" && <BackspaceIcon fontSize="large" />}
                  {num === "search" && (
                    <span>
                      <PersonSearchIcon fontSize="large" />
                      Search
                    </span>
                  )}
                </Button>
              </Grid>
            )
          )}
        </Grid>
      </div>
    </>
  );
}

export default Keypad;

import { useState, useEffect } from "react";

const DEBOUNCE_DELAY = 300; // will submit after 300 milliseconds of no input

const BarcodeScanner = ({ onScan }) => {
  const [barcode, setBarcode] = useState("");

  useEffect(() => {
    if (!barcode) return;

    const timeoutId = setTimeout(() => {
      onScan(barcode);
      setBarcode("");
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timeoutId);
  }, [barcode, onScan]);

  const handleKeyDown = (e) => {
    const char = String.fromCharCode(e.which);

    // Filter out non-numeric characters. Modify as needed.
    if (/[0-9]/.test(char)) {
      setBarcode((prev) => prev + char);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);
};

export default BarcodeScanner;

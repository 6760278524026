import { useState, useEffect } from "react";
import { Button, Link, Typography } from "@mui/material";
import {
  useKioskAppointments,
  useRefreshSchedule,
} from "../../queries/appointments";
import { useRefreshClients, useKioskClients } from "../../queries/clients";
import { usePublicChiropractors } from "../../queries/chiropractors";
import { useQueryClient } from "react-query";
import CheckInCard from "./CheckInCard";
import { useAtomValue } from "jotai";
import { currentLocationAtom } from "../../store";
import { useWalkInChiropractors } from "../../queries/locations";
import Loading from "../Loading";

export default function CheckInList({ phone, barcode, onBack, onCheckIn }) {
  const queryClient = useQueryClient();
  const currentLocation = useAtomValue(currentLocationAtom);
  const { data: clients, isLoading: clientsLoading } = useKioskClients({
    phone,
    barcode,
  });
  const { data: chiropractors, isLoading: chiropractorsLoading } =
    usePublicChiropractors();
  const clientBarcodes = clients?.map((client) => client?.attributes?.barcode);
  const { data: appointments, isLoading: appointmentsLoading } =
    useKioskAppointments(currentLocation?.id, clientBarcodes);
  const { data: walkInChiropractors, isLoading: walkInChiropractorsLoading } =
    useWalkInChiropractors(currentLocation?.id);
  const { mutate: refreshSchedule, isLoading: refreshingSchedule } =
    useRefreshSchedule();
  const { mutate: refreshClients, isLoading: refreshingClients } =
    useRefreshClients();

  const apptChiropractors =
    chiropractors && appointments
      ? chiropractors.filter((chiro) =>
          appointments.some(
            (appointment) =>
              appointment.attributes.chiropractorMboId ===
              chiro.attributes.mboId
          )
        )
      : [];

  const [forcingRefresh, setForcingRefresh] = useState(false);
  const [checkInVisits, setCheckInVisits] = useState([]);

  const updateCheckInVisits = (checkInVisit) => {
    let newCheckInVisits = [...checkInVisits];
    const indexToDelete = newCheckInVisits.findIndex(
      (visit) => visit.clientId === checkInVisit.clientId
    );
    if (indexToDelete !== -1) {
      newCheckInVisits.splice(indexToDelete, 1);
    }

    if (checkInVisit.prefChiropractorId !== null) {
      newCheckInVisits.push(checkInVisit);
    }

    setCheckInVisits(newCheckInVisits);
  };

  const forceRefresh = async () => {
    setForcingRefresh(true);
    refreshSchedule(true);
    refreshClients();
  };

  const handleCheckIn = () => {
    onCheckIn(checkInVisits);
  };

  useEffect(() => {
    if (forcingRefresh && !refreshingClients && !refreshingSchedule) {
      setForcingRefresh(false);
      queryClient.resetQueries({ queryKey: ["kioskClients"] });
      queryClient.resetQueries({ queryKey: ["kioskAppointments"] });
      queryClient.resetQueries({ queryKey: ["walkInChiropractors"] });
    }
  }, [forcingRefresh, refreshingClients, refreshingSchedule]);

  if (forcingRefresh)
    return (
      <Loading
        size={64}
        caption="Refreshing database. This may take a minute..."
      />
    );

  if (
    clientsLoading ||
    chiropractorsLoading ||
    walkInChiropractorsLoading ||
    appointmentsLoading
  )
    return <Loading size={64} caption="Searching..." />;

  // combine chiropractors available for walk-in and where client has appt; remove duplicates and sort
  const availableChiros = [...apptChiropractors, ...walkInChiropractors]
    .reduce((acc, cur) => {
      if (!acc.some((obj) => obj.id === cur.id)) {
        acc.push(cur);
      }
      return acc;
    }, [])
    .sort((a, b) => a.id - b.id);

  return (
    <div>
      {clients?.length === 0 && phone && (
        <div>
          Sorry, we couldn't find ({phone.slice(0, 3)}) {phone.slice(3, 6)}-
          {phone.slice(6)} in our database.
        </div>
      )}

      {clients?.length === 0 && barcode && (
        <div>Sorry, we couldn't find barcode # {barcode} in our database.</div>
      )}

      {clients?.length > 0 && (
        <div>
          <Typography variant="h5">
            Which chiropractor would you like to see today?
          </Typography>
          {clients.map((client) => (
            <CheckInCard
              key={client.id}
              client={client}
              chiropractors={availableChiros}
              onSelect={updateCheckInVisits}
              appointments={appointments}
            />
          ))}
        </div>
      )}
      <div>
        <Button onClick={onBack} sx={{ m: 2 }}>
          Back
        </Button>
        <Button
          onClick={handleCheckIn}
          variant="contained"
          sx={{ m: 2 }}
          disabled={!checkInVisits.length}
        >
          Check In
        </Button>
      </div>

      {!forcingRefresh && (
        <div>
          <Typography sx={{ mt: 8 }} variant="body2">
            Not seeing what you expect?
            <br />
            <Link onClick={forceRefresh} style={{ cursor: "pointer" }}>
              Refresh the database
            </Link>
          </Typography>
        </div>
      )}
    </div>
  );
}

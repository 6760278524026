import { getData, postData, patchData, deleteData } from ".";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSetAtom } from "jotai";
import { useAtomValue } from "jotai/utils";
import {
  tokenAtom,
  currentLocationAtom,
  alertAtom,
  waitingForMindbodyAtom,
} from "../store";
import { useNavigate } from "react-router-dom";
import { useAppSettings } from "./settings";

export const useWaitlistVisits = () => {
  const token = useAtomValue(tokenAtom);
  const currentLocation = useAtomValue(currentLocationAtom);
  const { data: appSettings } = useAppSettings();
  const queryClient = useQueryClient();

  return useQuery(
    ["waitlistVisits", currentLocation.id],
    async () => {
      const { data: visits, included: clients } = await getData(
        `/api/visits/waitlist/${currentLocation.id}`,
        token
      );
      visits.forEach((item) =>
        queryClient.setQueryData([item.type, item.id], item)
      );
      clients?.forEach((item) =>
        queryClient.setQueryData([item.type, item.id], item)
      );
      return visits;
    },
    {
      enabled: !!token && !!currentLocation.id,
      refetchInterval: appSettings?.attributes?.waitlistRefreshInterval || 5000,
    }
  );
};

export const useVisit = (visitId) => {
  const token = useAtomValue(tokenAtom);
  const setAlert = useSetAtom(alertAtom);
  const navigate = useNavigate();

  return useQuery(
    ["visits", visitId],
    async () => {
      const { data: visit } = await getData(`/api/visits/${visitId}`, token);
      return visit;
    },
    {
      enabled: !!token && !!visitId,
      onError: (error) => {
        setAlert({ content: String(error) });
        navigate("/");
      },
    }
  );
};

export const useClientVisits = (clientId) => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();

  return useQuery(
    ["clientVisits", clientId],
    async () => {
      const { data: visits } = await getData(
        `/api/clients/${clientId}/visits`,
        token
      );
      visits.forEach((item) =>
        queryClient.setQueryData([item.type, item.id], item)
      );

      return visits;
    },
    { enabled: !!token && !!clientId }
  );
};

export const useCallVisit = (chiropractorId) => {
  const token = useAtomValue(tokenAtom);
  const currentLocation = useAtomValue(currentLocationAtom);
  const queryClient = useQueryClient();
  const queryKey = ["waitlistVisits", currentLocation.id];

  return useMutation(
    (visitId) =>
      patchData(`/api/visits/${visitId}/call`, token, {
        data: {
          id: visitId,
          type: "visits",
          relationships: {
            chiropractor: {
              data: {
                id: chiropractorId,
                type: "chiropractors",
              },
            },
          },
        },
      }),
    {
      enabled: !!token,

      onMutate: async (visitId) => {
        // optimistically update waitlist
        await queryClient.cancelQueries(queryKey);
        const previousWaitlist = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, (waitlist) => {
          const visitIndex = waitlist.findIndex(
            (waitlistVisit) => waitlistVisit.id === visitId
          );
          waitlist[visitIndex].relationships.chiropractor = {
            data: { id: chiropractorId },
          };
          waitlist[visitIndex].attributes.timeCalled = chiropractorId
            ? new Date().toJSON().slice(0, 19).replace("T", " ")
            : null;
          return waitlist;
        });
        return { previousWaitlist };
      },
      onError: (_error, _waitlist, context) => {
        queryClient.setQueryData(queryKey, context.previousWaitlist);
      },
      onSettled: () => {
        // refetch waitlist
        queryClient.invalidateQueries(queryKey);
      },
    }
  );
};

export const useUpdateVisit = () => {
  const token = useAtomValue(tokenAtom);
  const setAlert = useSetAtom(alertAtom);
  const queryClient = useQueryClient();

  return useMutation(
    (visit) => {
      // only update certain properties
      visit.attributes = {
        visitNote: visit.attributes.visitNote,
        visitDate: visit.attributes.visitDate,
      };
      delete visit.relationships;

      return patchData(`/api/visits/${visit.id}`, token, { data: visit });
    },
    {
      enabled: !!token,
      onError: (error) => {
        console.error(error);
        setAlert({ content: "Error updating visit" });
      },
      onSuccess: async ({ data: visit }) => {
        // update visit
        const visitKey = ["visits", visit.id];
        await queryClient.cancelQueries(visitKey);
        queryClient.setQueryData(visitKey, visit);

        // update clientVisits
        const clientVisitsKey = [
          "clientVisits",
          visit.relationships.client.data.id,
        ];
        await queryClient.cancelQueries(clientVisitsKey);
        const previousClientVisits = queryClient.getQueryData(clientVisitsKey);
        !!previousClientVisits &&
          queryClient.setQueryData(clientVisitsKey, (clientVisits) => {
            const visitIndex = clientVisits.findIndex(
              (clientVisit) => clientVisit.id === visit.id
            );
            clientVisits[visitIndex] = visit;
            return clientVisits;
          });
      },
    }
  );
};

export const useCreateVisit = () => {
  const token = useAtomValue(tokenAtom);
  const setAlert = useSetAtom(alertAtom);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    (visit) => postData(`/api/visits`, token, { data: visit }),
    {
      enabled: !!token,
      onError: (error) => {
        console.error(error);
        setAlert({ content: "Error creating visit" });
      },
      onSuccess: async ({ data: visit }) => {
        queryClient.setQueryData(["visits", visit.id], visit);
        navigate(`/visit/${visit.id}`);
      },
    }
  );
};

export const useCheckIn = () => {
  const token = useAtomValue(tokenAtom);
  const setAlert = useSetAtom(alertAtom);

  return useMutation(
    (visits) =>
      postData(`/api/visits/check-in`, token, {
        data: visits.map((visit) => ({ type: "visits", attributes: visit })),
      }),
    {
      enabled: !!token,
      onError: (error) => {
        console.error(error);
        setAlert({ content: "Error checking in" });
      },
    }
  );
};

export const useCompleteVisit = (locationId) => {
  const token = useAtomValue(tokenAtom);
  const setAlert = useSetAtom(alertAtom);
  const setWaitingForMindbody = useSetAtom(waitingForMindbodyAtom);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const queryKey = ["waitlistVisits", locationId];

  return useMutation(
    (visitId) => patchData(`/api/visits/${visitId}/complete`, token),
    {
      enabled: !!token,

      onMutate: async (visitId) => {
        setWaitingForMindbody(true);

        // optimistic update waitlist
        await queryClient.cancelQueries(queryKey);
        const previousWaitlist = queryClient.getQueryData(queryKey);
        !!previousWaitlist &&
          queryClient.setQueryData(queryKey, (waitlist) => {
            const visitIndex = waitlist.findIndex(
              (waitlistVisit) => waitlistVisit.id === visitId
            );
            waitlist.splice(visitIndex, 1);
            return waitlist;
          });

        // optimistically update visit
        const visitKey = ["visits", visitId];
        await queryClient.cancelQueries(visitKey);
        const previousVisit = queryClient.getQueryData(visitKey);
        if (!!previousVisit) {
          const completedVisit = { ...previousVisit };
          completedVisit.attributes.timeCompleted = new Date();
          queryClient.setQueryData(visitKey, completedVisit);
        }

        // immediately navigate to waitlist while waiting for visit to complete
        navigate("/waitlist");

        return { previousWaitlist, previousVisit };
      },
      onSettled: () => setWaitingForMindbody(false),
      onSuccess: (res) => {
        if (res.errors) {
          setAlert({
            title: res.errors[0].title,
            content: res.errors[0].detail,
          });
          return;
        }
        queryClient.setQueryData(["visits", res.data.id], res.data);
        if (
          !!res.data.attributes.timeCompleted &&
          !res.data.attributes.timeCheckedOut
        ) {
          setAlert({
            title: "Payment Required",
            content:
              "Please collect a payment for the visit that was just completed.",
          });
        }
      },
      onError: (err, _vars) => {
        setAlert({ content: String(err) });
      },
    }
  );
};

export const useCancelVisit = () => {
  const token = useAtomValue(tokenAtom);
  const currentLocation = useAtomValue(currentLocationAtom);
  const queryClient = useQueryClient();
  const queryKey = ["waitlistVisits", currentLocation.id];

  return useMutation((visitId) => deleteData(`/api/visits/${visitId}`, token), {
    enabled: !!token,

    // optimistic update
    onMutate: async (visitId) => {
      await queryClient.cancelQueries(queryKey);
      const previousWaitlist = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (waitlist) => {
        const visitIndex = waitlist.findIndex(
          (waitlistVisit) => waitlistVisit.id === visitId
        );
        waitlist.splice(visitIndex, 1);
        return waitlist;
      });
      return { previousWaitlist };
    },
    onError: (_error, _waitlist, context) => {
      queryClient.setQueryData(queryKey, context.previousWaitlist);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};

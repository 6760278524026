import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import { useAtom } from "jotai";
import { alertAtom } from "../store";

export default function AlertDialog() {
  const [alert, setAlert] = useAtom(alertAtom);

  if (!alert) return;

  return (
    <Dialog open={!!alert}>
      <DialogTitle>{alert.title || "Error"}</DialogTitle>
      <DialogContent>{alert.content}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => setAlert(null)}
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { useEffect } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { usePublicChiropractors } from "../../queries/chiropractors";
import { DateTime } from "luxon";
import CheckIcon from "@mui/icons-material/Check";
import { useRandomWelcomeMessage } from "../../queries/welcomeMessages";
import parse from "html-react-parser";
import { useAppSettings } from "../../queries/settings";
import BirthdayConfetti from "../BirthdayConfetti";
import Loading from "../Loading";

export default function CheckInConfirmation({
  checkingIn,
  checkedInVisits,
  checkedInClients,
  onFinish,
}) {
  const { data: settings } = useAppSettings();
  const { data: chiropractors } = usePublicChiropractors();

  const getBirthdayFromClients = (checkedInClients) => {
    let birthday = false;
    const birthDates = checkedInClients?.map((client) =>
      DateTime.fromISO(client.attributes?.birthDate)
    );
    const today = DateTime.local();
    birthDates?.forEach((birthDate) => {
      const birthdayThisYear = birthDate.set({ year: today.year });
      const diffInDays = today.diff(birthdayThisYear, "days").days;
      const flagBirthday =
        diffInDays >= 0
          ? Number(settings.flagBirthdayAfter) >= diffInDays
          : Number(settings.flagBirthdayBefore) >= diffInDays * -1;
      if (flagBirthday) birthday = birthDate;
    });
    return birthday;
  };

  const birthday = getBirthdayFromClients(checkedInClients);
  const { data: welcomeMessage, isLoading: welcomeMessageLoading } =
    useRandomWelcomeMessage(!!birthday ? "birthday" : "regular");

  // automatically exit the screen after 15 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      onFinish();
    }, 15000); // 15000 milliseconds = 15 seconds

    return () => clearTimeout(timer);
  }, [onFinish]);

  if (
    welcomeMessageLoading ||
    checkingIn ||
    !checkedInVisits?.length > 0 ||
    !checkedInClients?.length > 0
  )
    return <Loading size={64} caption="Checking in..." />;

  return (
    <div>
      <div>
        <Typography variant="h5" sx={{ maxWidth: 480 }}>
          {parse(welcomeMessage?.attributes?.message || "")}
        </Typography>
        <List dense sx={{ display: "inline-block", mt: 3 }}>
          {checkedInVisits.map((visit) => {
            const client = checkedInClients.find(
              (client) => client.id === visit.relationships.client.data.id
            );
            const chiropractor = chiropractors.find(
              (chiro) =>
                chiro.id === visit.relationships.prefChiropractor?.data?.id
            );

            let secondaryInfo = chiropractor?.attributes?.displayName
              ? chiropractor.attributes.displayName
              : "First available";
            if (visit.attributes?.appointmentTime) {
              secondaryInfo += ` | ${DateTime.fromISO(
                visit.attributes.appointmentTime
              )
                .toUTC()
                .toFormat("t")
                .toLowerCase()} appt`;
            }

            return (
              <ListItem key={visit.id}>
                <ListItemIcon>
                  <CheckIcon color="primary" sx={{ fontSize: "2.4rem" }} />
                </ListItemIcon>
                <ListItemText
                  primary={`${
                    client.attributes.firstName
                  } ${client.attributes.lastName.charAt(0)}.`}
                  primaryTypographyProps={{ sx: { fontSize: "1.3rem" } }}
                  secondary={secondaryInfo}
                  secondaryTypographyProps={{ sx: { fontSize: "1.1rem" } }}
                />
              </ListItem>
            );
          })}
        </List>
      </div>
      <Button variant="outlined" sx={{ m: 3 }} onClick={onFinish}>
        Finished
      </Button>
      {checkedInClients.map((client) => (
        <BirthdayConfetti
          birthDate={client.attributes?.birthDate}
          key={client.id}
        />
      ))}
    </div>
  );
}

import { useState } from "react";
import {
  Card,
  CardContent,
  Avatar,
  List,
  ListItemButton,
  Typography,
  Radio,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import { DateTime } from "luxon";
import { useAppSettings } from "../../queries/settings";
import { useAtomValue } from "jotai/utils";
import { currentLocationAtom } from "../../store";

export default function CheckInCard({
  client,
  chiropractors,
  appointments,
  onSelect,
}) {
  const [selectedChiroId, setSelectedChiroId] = useState(null);
  const { data: appSettings } = useAppSettings();
  const currentLocation = useAtomValue(currentLocationAtom);

  const handleChiroChange = (chiroId) => {
    const newChiroId = chiroId === selectedChiroId ? null : chiroId;

    const chiropractor = chiropractors.find((chiro) => chiro.id === newChiroId);
    const appointment = appointments?.find(
      (appt) =>
        (appt.attributes.chiropractorMboId === chiropractor?.attributes.mboId ||
          !chiropractor) &&
        appt.attributes.clientBarcode === client.attributes.barcode
    );

    onSelect({
      clientId: client.id,
      prefChiropractorId: newChiroId,
      locationId: currentLocation?.id,
      appointmentTime: appointment?.attributes?.appointmentTime || undefined,
      mboId: appointment?.attributes?.mboId || undefined,
    });

    setSelectedChiroId(newChiroId);
  };

  const chiroOption = (chiro) => {
    const apptInfo =
      appointments?.length > 0 &&
      appointments.some(
        (appt) =>
          (appt?.attributes.chiropractorMboId === chiro?.attributes.mboId ||
            !chiro) &&
          appt.attributes.clientBarcode === client.attributes.barcode
      )
        ? `${DateTime.fromISO(appointments[0].attributes.appointmentTime)
            .toUTC()
            .toFormat("t")
            .toLowerCase()} appt`
        : "";
    return (
      <>
        <ListItemAvatar>
          <Avatar
            alt={chiro?.attributes?.fullName || "First available"}
            src={
              chiro?.attributes?.photoUrl ||
              appSettings?.anyChiropractorPhotoUrl
            }
            sx={{ marginRight: 1, width: 80, height: 80 }}
          />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ sx: { fontSize: "1.3rem" } }}
          primary={chiro?.attributes?.displayName || "First available"}
          secondaryTypographyProps={{ sx: { fontSize: "1.1rem" } }}
          secondary={apptInfo}
        />
      </>
    );
  };

  return (
    <Card
      sx={{
        display: "inline-block",
        m: 2,
        borderWidth: 2,
        borderRadius: 4,
        borderColor:
          selectedChiroId === null ? "rgba(0, 0, 0, 0.12)" : "primary.main",
      }}
      variant="outlined"
    >
      <CardContent>
        <Typography variant="h6">
          {client.attributes.firstName} {client.attributes.lastName.charAt(0)}.
        </Typography>
        <List>
          <ListItemButton
            onClick={() => handleChiroChange("0")}
            selected={selectedChiroId === "0"}
          >
            <Radio checked={selectedChiroId === "0"} />
            {chiroOption(null)}
          </ListItemButton>
          {chiropractors.map((chiro) => (
            <ListItemButton
              key={chiro.id}
              onClick={() => handleChiroChange(chiro.id)}
              selected={selectedChiroId === chiro.id}
            >
              <Radio checked={selectedChiroId === chiro.id} />
              {chiroOption(chiro)}
            </ListItemButton>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

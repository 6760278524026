import React from "react";
//import { useTheme } from "@mui/material/styles";
import {
  IconButton,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
} from "@mui/material";
import { MoreVert as Kebab } from "@mui/icons-material";
import { Draggable } from "react-beautiful-dnd";
import {
  useChiropractor,
  useUserChiropractor,
} from "../../queries/chiropractors";
import { useCallVisit } from "../../queries/visits";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../queries/clients";

export default function WaitlistItem({
  visit,
  nextVisit,
  index,
  selected,
  openMenu,
}) {
  const { data: userChiropractor } = useUserChiropractor();
  const { data: client } = useClient(visit.relationships.client.data.id);
  const { data: chiropractor } = useChiropractor(
    visit.relationships.chiropractor.data?.id
  );
  const { data: prefChiropractor } = useChiropractor(
    visit.relationships.prefChiropractor.data?.id
  );
  const { mutate: callVisit } = useCallVisit(userChiropractor?.id);
  const navigate = useNavigate();

  //const theme = useTheme();
  const styles = {
    visitInfo: {
      minWidth: 128,
      maxWidth: "35%",
    },
    visitCalled: {
      opacity: 0.5,
    },
  };
  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,
    ...(isDragging && {
      background: "rgb(235,235,235)",
    }),
    cursor: "pointer",
  });

  const row = {};
  row.name =
    `${client.attributes.firstName?.trim()} ` +
    (!!client.attributes.nickname?.trim() &&
    client.attributes.nickname?.trim() !== client.attributes.firstName
      ? `"${client.attributes.nickname?.trim()}" `
      : "") +
    client.attributes.lastName;
  const waitMinutes =
    Math.floor(
      (new Date() - new Date(visit.attributes.timeCheckedIn)) / (1000 * 60)
    ) - new Date().getTimezoneOffset();

  row.status = visit.attributes.timeCalled
    ? "Called: " + chiropractor?.attributes.displayName + ""
    : `${waitMinutes} minutes`;
  row.appt =
    (visit.attributes.appointmentTime
      ? new Date(visit.attributes.appointmentTime)
          .toLocaleTimeString([], {
            hour: "numeric",
            minute: "2-digit",
            timeZone: "UTC",
          })
          .toLowerCase()
      : "Walk-in") +
    " for " +
    (prefChiropractor?.attributes?.displayName
      ? prefChiropractor.attributes.displayName
      : "Any");

  return (
    <Draggable
      key={visit.id}
      draggableId={String(visit.id)}
      index={index}
      isDragDisabled={visit.attributes.timeCalled?.length > 0}
    >
      {(provided, snapshot) => (
        <ListItem
          button
          dense
          divider={
            !nextVisit?.attributes?.groupGuid ||
            nextVisit.attributes.groupGuid !== visit.attributes.groupGuid
          }
          ContainerProps={{ ref: provided.innerRef }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
          sx={visit.attributes.timeCalled ? styles.visitCalled : null}
          onClick={() => {
            if (!visit.attributes.timeCalled) {
              callVisit(visit.id);
            }
            navigate("/visit/" + visit.id);
          }}
          selected={selected}
        >
          <ListItemAvatar>
            <Avatar src={client?.attributes?.photoUrl || "//:0"}>
              {(client?.attributes?.nickname?.charAt(0) ||
                client?.attributes?.firstName?.charAt(0)) +
                client?.attributes?.lastName?.charAt(0)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={row.name}
            primaryTypographyProps={{ variant: "subtitle1" }}
            secondary={row.status}
            sx={styles.visitInfo}
          />
          <ListItemText
            primary={row.appt}
            primaryTypographyProps={{ sx: { fontSize: "1.15rem" } }}
          />
          <ListItemSecondaryAction>
            <IconButton onClick={(e) => openMenu(e, index)}>
              <Kebab />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </Draggable>
  );
}

import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

export default function Loading({ size, caption, m }) {
  return (
    <Box sx={{ textAlign: "center", m: m || 2 }}>
      <Box style={{ display: "inline-block", margin: "auto" }}>
        <CircularProgress size={size || 96} />
        {caption && <Typography>{caption}</Typography>}
      </Box>
    </Box>
  );
}

import React from "react";
import { Box, CircularProgress } from "@mui/material";

import GoogleLogin from "./GoogleLogin";
import SelectLocation from "./SelectLocation";
import { useUser } from "../../queries/users";
import { useAppSettings } from "../../queries/settings";

export default function LoginPage() {
  const { data: user } = useUser();
  const { data: appSettings } = useAppSettings();

  return (
    <Box style={{ textAlign: "center", margin: 16 }}>
      <Box style={{ display: "inline-block", margin: "auto" }}>
        {!appSettings ? (
          <CircularProgress size={96} />
        ) : (
          <>
            <img src={appSettings.logo} alt="logo" style={{ maxWidth: 256 }} />
            <div>{!user ? <GoogleLogin /> : <SelectLocation />}</div>
          </>
        )}
      </Box>
    </Box>
  );
}

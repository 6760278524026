import React, { useEffect } from "react";
import axios from "axios";
import { useAtom } from "jotai";
import { tokenAtom } from "../../store";
import { CircularProgress, Typography } from "@mui/material";

export default function GoogleLogin() {
  const [token, setToken] = useAtom(tokenAtom);

  // load Google GSI script after component has rendered
  useEffect(() => {
    // set token, unbind callback, remove script on successful login
    const googleCallback = async (e) => {
      try {
        const res = await axios.post(
          "/api/auth/google",
          {},
          {
            headers: { Authorization: `Bearer ${e.credential}` },
            withCredentials: true,
          }
        );
        setToken(res.data.token);
      } catch (err) {
        console.error(err.response.data.errors[0]?.detail);
        alert(err.response.data.errors[0]?.detail);
      }

      window.googleCallback = null;
      document.getElementById("gsi-script")?.remove();
    };

    // bind callback function
    window.googleCallback = googleCallback;

    // load GSI script
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.id = "gsi-script";
    document.querySelector("body")?.appendChild(script);
  }, [setToken]);

  // token is set, but login page is still showing because user is not set
  if (token)
    return (
      <div>
        <CircularProgress />
        <Typography>Google says you're legit!</Typography>
        <Typography>Logging you in...</Typography>
      </div>
    );

  return (
    <div>
      <div
        id="g_id_onload"
        data-client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        data-auto_prompt="true"
        data-auto_select="true"
        data-callback="googleCallback"
      ></div>
      <div
        className="g_id_signin"
        data-type="standard"
        data-size="large"
        data-theme="outline"
        data-text="sign_in_with"
        data-shape="pill"
        data-logo_alignment="left"
        style={{ display: "inline-block" }}
      ></div>
    </div>
  );
}

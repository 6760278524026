import {
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  useSubscription,
  useActivateSubscription,
  useMboEvents,
} from "../../queries/mindbody";
import Loading from "../Loading";

export default function Mindbody() {
  const { data: subscription, isLoading: subscriptionLoading } =
    useSubscription();
  const { data: mboEvents } = useMboEvents();
  const { mutate: activateSubscription } = useActivateSubscription();

  if (subscriptionLoading) return <Loading />;

  return (
    <div>
      <Typography variant="h6">Webhook Subscription:</Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Status"
            secondary={subscription.attributes.status}
          />
          <ListItemSecondaryAction>
            <Button
              onClick={() =>
                activateSubscription(
                  subscription.attributes.status !== "Active"
                )
              }
            >
              {subscription.attributes.status === "Active"
                ? "Deactivate"
                : "Activate"}
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Event Ids"
            secondary={subscription.attributes.eventIds?.join(", ")}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Webhook URL"
            secondary={subscription.attributes.webhookUrl}
          />
        </ListItem>
      </List>
      <Typography variant="h6">Unprocessed Events:</Typography>
      <List>
        {mboEvents?.map((event) => (
          <ListItem key={event.id}>
            <ListItemText
              primary={event.attributes.eventId}
              secondary={event.attributes.eventInstanceOriginationDateTime}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { DateTime, Interval } from "luxon";
import { useClientVisits } from "../../queries/visits";
import {
  useAllChiropractors,
  useUserChiropractor,
} from "../../queries/chiropractors";
import { usePublicLocations } from "../../queries/locations";
import { useHighlights } from "../../queries/highlights";
import Loading from "../Loading";

export default function ClientVisits({ currentVisitId, clientId }) {
  const { data: visits, isLoading: visitsLoading } = useClientVisits(clientId);
  const { data: chiropractors, isLoading: chiropractorsLoading } =
    useAllChiropractors();
  const { data: userChiropractor } = useUserChiropractor();
  const { data: locations, isLoading: locationsLoading } = usePublicLocations();
  const { data: highlights, isLoading: highlightsLoading } = useHighlights();
  const [onlyMe, setOnlyMe] = useState(false);

  if (
    visitsLoading ||
    chiropractorsLoading ||
    locationsLoading ||
    highlightsLoading
  )
    return <Loading />;

  const highlightNote = (note) => {
    const paragraphs = note.split("\n");

    const highlightedNote = paragraphs.map((paragraph, p) => {
      const sentences = paragraph.split(".");

      return sentences.map((sentence, s) => {
        const highlightIndex = highlights.findIndex(
          (highlight) =>
            sentence.toLowerCase().search(highlight.phrase.toLowerCase()) >= 0
        );
        const color = highlights[highlightIndex]?.color;

        return (
          <span
            key={`sentence-${p}-${s}`}
            style={{ color: color ? color : "" }}
          >
            {(p > 0 && s === 0 ? "\n" : "") +
              sentence +
              (sentences.length === s + 1 ? "" : ".")}
          </span>
        );
      });
    });

    return <span>{highlightedNote}</span>;
  };

  const filteredVisits = onlyMe
    ? visits.filter(
        (visit) =>
          visit.relationships.chiropractor.data.id === userChiropractor.id
      )
    : visits;

  const daysAgo = (specificDateString) => {
    const specificDate = DateTime.fromISO(specificDateString);
    const currentDate = DateTime.local();

    const interval = Interval.fromDateTimes(specificDate, currentDate);
    const duration = interval.toDuration(["years", "months", "days"]);

    const years = Math.floor(duration.years);
    const months = Math.floor(duration.months);
    const days = Math.floor(duration.days);

    let agoString = "";
    if (years === 1) agoString += `${years} year`;
    else if (years > 1) agoString += `${years} years`;
    if (agoString.length > 0 && months > 0) agoString += ", ";
    if (months === 1) agoString += `${months} month`;
    else if (months > 1) agoString += `${months} months`;
    if (agoString.length > 0 && days > 0) agoString += ", ";
    if (days === 1) agoString += `${days} day`;
    else if (days > 1) agoString += `${days} days`;

    return agoString || "today";
  };

  return (
    <React.Fragment>
      <Box>
        Previous visits ({filteredVisits?.length}):
        <div style={{ float: "right" }}>
          <FormControlLabel
            control={
              <Switch
                checked={onlyMe}
                onChange={(e) => setOnlyMe(e.target.checked)}
                name="onlyMe"
              />
            }
            label="Only me"
          />
        </div>
      </Box>
      <Box style={{ flex: "1 1 50%", overflowY: "auto" }}>
        <List style={{ whiteSpace: "pre-wrap" }}>
          {filteredVisits?.map((visit, i) => {
            const highlightedNote = visit.attributes.visitNote
              ? highlightNote(visit.attributes.visitNote)
              : "";

            return (
              <React.Fragment key={visit.id}>
                <ListItem
                  key={visit.id + "-header"}
                  selected={visit.id === currentVisitId}
                  component={Link}
                  to={`/visit/${visit.id}`}
                  button
                >
                  <ListItemText disableTypography sx={{ fontWeight: 500 }}>
                    <div style={{ float: "left" }}>
                      {DateTime.fromSQL(
                        visit.attributes.visitDate
                      ).toLocaleString({
                        ...DateTime.DATE_SHORT,
                        weekday: "long",
                      }) +
                        (i === 0
                          ? " (" + daysAgo(visit.attributes.visitDate) + ")"
                          : "")}
                    </div>
                    <div style={{ float: "right" }}>
                      {chiropractors.find(
                        (chiro) =>
                          chiro.id ===
                          visit.relationships?.chiropractor?.data?.id
                      )?.attributes.displayName +
                        " | " +
                        locations.find(
                          (loc) =>
                            loc.id === visit.relationships?.location?.data?.id
                        )?.attributes.name}
                    </div>
                  </ListItemText>
                </ListItem>
                <ListItem
                  sx={{ fontSize: "1.15rem" }}
                  key={visit.id + "-detail"}
                  selected={visit.id === currentVisitId}
                  divider
                >
                  {highlightedNote}
                </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      </Box>
    </React.Fragment>
  );
}

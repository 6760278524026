import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@mui/material";
import Users from "./Users";
import Roles from "./Roles";
import Permissions from "./Permissions";
import Mindbody from "./Mindbody";

export default function Settings() {
  return (
    <Container>
      <Typography variant="h2">Settings</Typography>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">General Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>Misc settings...</AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Business & Locations</Typography>
        </AccordionSummary>
        <AccordionDetails>Business info...</AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Users</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Users />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Roles</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Roles />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Permissions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Permissions />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Mindbody</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Mindbody />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

import { postData } from ".";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useAtom, useSetAtom } from "jotai";
import { permissionsAtom, tokenAtom } from "../store";

export const useRefreshToken = () => {
  const [token, setToken] = useAtom(tokenAtom);

  return useQuery(
    ["refreshToken"],
    () => postData(`/api/auth/refresh-token`, token),
    {
      refetchInterval: token ? 1000 * 60 * 4 + 59000 : false, // refetch token every 4:59 if logged in
      refetchIntervalInBackground: true,
      cacheTime: 1000 * 60 * 15, // token is invalid after 15 minutes
      staleTime: 1000 * 60 * 15, // keep fresh while token is valid
      onSuccess: (res) => {
        if (res.data?.type === "tokens") setToken(res.data.id);
      },
    }
  );
};

export const useLogout = () => {
  const [token, setToken] = useAtom(tokenAtom);
  const setPermissions = useSetAtom(permissionsAtom);
  const queryClient = useQueryClient();

  return useMutation(() => postData(`/api/auth/logout`, token), {
    enabled: !!token,
    onSuccess: () => {
      setToken(null);
      setPermissions([]);
      queryClient.removeQueries();
    },
  });
};

import { getData, postData, patchData } from ".";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useAtomValue, useSetAtom } from "jotai";
import { tokenAtom, alertAtom } from "../store";

export const useRoles = () => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["roles"],
    async () => {
      const { data: roles } = await getData("/api/roles", token);
      return roles;
    },
    { enabled: !!token }
  );
};

export const useCreateRole = () => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();
  const setAlert = useSetAtom(alertAtom);

  return useMutation(
    (role) => {
      return postData(`/api/roles`, token, { data: role });
    },
    {
      enabled: !!token,
      onSettled: async ({ data: role, errors }) => {
        // handle errors
        if (!!errors?.[0]) {
          setAlert({ title: errors[0], content: errors[0].detail });
          return;
        } else if (!role?.id) {
          setAlert({ content: "Error creating role." });
          return;
        }

        // add new role to roles list
        await queryClient.cancelQueries(["roles"]);
        const previousRoles = queryClient.getQueryData(["roles"]);
        !!previousRoles &&
          queryClient.setQueryData(["roles"], [...previousRoles, role]);
      },
    }
  );
};

export const useUpdateRole = () => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();
  const setAlert = useSetAtom(alertAtom);

  return useMutation(
    (role) => {
      return patchData(`/api/roles/${role?.id}`, token, { data: role });
    },
    {
      enabled: !!token,
      onSettled: async ({ data: updatedRole, errors }) => {
        // handle errors
        if (!!errors?.[0]) {
          setAlert({ title: errors[0], content: errors[0].detail });
          return;
        } else if (!updatedRole?.id) {
          setAlert({ content: "Error updating role." });
          return;
        }

        // update role in roles list
        await queryClient.cancelQueries(["roles"]);
        const previousRoles = queryClient.getQueryData(["roles"]);
        !!previousRoles &&
          queryClient.setQueryData(["roles"], (roles) => {
            const roleIndex = roles.findIndex((u) => u.id === updatedRole.id);
            roles[roleIndex] = updatedRole;
            return roles;
          });
      },
    }
  );
};

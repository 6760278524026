import { useNavigate } from "react-router-dom";
import { Button, Container, Typography } from "@mui/material";
import { useUserChiropractor } from "../queries/chiropractors";
import { useUser } from "../queries/users";
import Loading from "./Loading";
import { useAtomValue } from "jotai";
import { permissionsAtom } from "../store";

export default function Dashboard() {
  const navigate = useNavigate();
  const { data: user, isLoading: userLoading } = useUser();
  const {
    isLoading: chiroLoading,
    isError,
    error,
    data: chiro,
  } = useUserChiropractor();
  const permissions = useAtomValue(permissionsAtom);

  if (chiroLoading || userLoading) {
    return <Loading caption="Loading dashboard..." />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Container maxWidth={false} style={{ textAlign: "center", padding: 16 }}>
      <Typography variant="h6">
        CNS Dashboard for{" "}
        {chiro?.attributes.displayName ||
          `${user?.attributes.firstName} ${user?.attributes.lastName}`}
      </Typography>

      {(user?.attributes.isSuper || permissions?.includes("ehr")) && (
        <Button onClick={() => navigate("/waitlist")} sx={{ m: 1 }}>
          View Waitlist
        </Button>
      )}

      <Typography
        m={2}
        align="center"
        variant="subtitle2"
      >{`v${process.env.REACT_APP_VERSION}`}</Typography>
    </Container>
  );
}

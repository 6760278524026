import { useEffect, useState } from "react";
import Keypad from "./Keypad";
import CheckInList from "./CheckInList";
import { useRefreshSchedule } from "../../queries/appointments";
import { useRefreshClients, useKioskClients } from "../../queries/clients";
import { useCheckIn } from "../../queries/visits";
import CheckInConfirmation from "./CheckInConfirmation";
import BarcodeScanner from "./BarcodeScanner";
import KioskError from "./KioskError";
import { useAtomValue } from "jotai";
import { errorAtom } from "../../store";
import { useQueryClient } from "react-query";

export default function Kiosk() {
  const queryClient = useQueryClient();
  const { mutate: refreshSchedule } = useRefreshSchedule();
  const { mutate: refreshClients } = useRefreshClients();
  const [phone, setPhone] = useState(null);
  const [barcode, setBarcode] = useState(null);
  const { data: clients } = useKioskClients({ phone, barcode });
  const { mutate: checkIn, isLoading: checkingIn } = useCheckIn();
  const [checkedInVisits, setCheckedInVisits] = useState([]);
  const [checkedInClients, setCheckedInClients] = useState([]);

  const returnToKeypad = () => {
    setPhone(null);
    setBarcode(null);
    setCheckedInVisits([]);
    setCheckedInClients([]);
    queryClient.resetQueries({ queryKey: ["kioskClients"] });
    queryClient.resetQueries({ queryKey: ["kioskAppointments"] });
    queryClient.resetQueries({ queryKey: ["walkInChiropractors"] });
    queryClient.resetQueries({ queryKey: ["randomWelcomeMessage"] });
  };

  const handleCheckIn = (visits) => {
    checkIn(visits, {
      onSuccess: (data) => {
        if (data?.data) {
          const visits = data.data;
          setCheckedInVisits(visits);
          const clientsWithVisits = clients.filter((client) =>
            visits.some(
              (visit) => visit.relationships?.client?.data?.id === client.id
            )
          );
          setCheckedInClients(clientsWithVisits);
          setPhone(null);
          setBarcode(null);
        }
      },
    });
  };

  useEffect(() => {
    refreshSchedule();
    refreshClients();
    const interval = setInterval(() => {
      refreshSchedule();
    }, 1000 * 60 * 15); // run every 15 minutes

    return () => clearInterval(interval);
  }, []);

  const error = useAtomValue(errorAtom);
  if (error) return <KioskError />;

  if (checkingIn || checkedInVisits?.length > 0)
    return (
      <CheckInConfirmation
        checkingIn={checkingIn}
        checkedInVisits={checkedInVisits}
        checkedInClients={checkedInClients}
        onFinish={returnToKeypad}
      />
    );

  if (phone || barcode)
    return (
      <CheckInList
        phone={phone}
        barcode={barcode}
        onBack={returnToKeypad}
        onCheckIn={handleCheckIn}
      />
    );

  return (
    <>
      <Keypad onSearch={setPhone} />
      <BarcodeScanner onScan={setBarcode} />
    </>
  );
}

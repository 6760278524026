import { postData, getData, generateQueryString } from ".";
import { useMutation, useQuery } from "react-query";
import { useAtomValue } from "jotai";
import { tokenAtom } from "../store";

export const useKioskAppointments = (locationId, barcodes) => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["kioskAppointments", { locationId, barcodes }],
    async () => {
      const queryString = generateQueryString({
        filter: { locationId, barcodes },
      });
      const { data: appointments } = await getData(
        `/api/appointments/check-in${queryString}`,
        token
      );

      return appointments;
    },
    {
      enabled: !!token && !!locationId && !!barcodes,
    }
  );
};

export const useRefreshSchedule = () => {
  const token = useAtomValue(tokenAtom);

  return useMutation(
    (force) => {
      return postData(
        `/api/appointments/refresh-schedule?force=${!!force}`,
        token
      );
    },
    {
      enabled: !!token,
      onSuccess: async (data) => {
        if (data.success) {
          console.log("Schedule refreshed at " + new Date());
        } else {
          console.log("Schedule NOT refreshed at " + new Date());
        }
      },
    }
  );
};

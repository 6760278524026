import React, { useState } from "react";
import axios from "axios";
import {
  FormControlLabel,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import {
  Cancel,
  PersonAdd,
  PersonAddDisabled,
  Visibility,
} from "@mui/icons-material";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import WaitlistItem from "./WaitlistItem";
import Loading from "../Loading";
import ConfirmDialog from "../ConfirmDialog";
import { useAtom } from "jotai";
import { currentLocationAtom, hideCalledAtom, onlyMeAtom } from "../../store";
import { useCancelVisit, useWaitlistVisits } from "../../queries/visits";
import {
  useUserChiropractor,
  useAllChiropractors,
} from "../../queries/chiropractors";
import { usePublicLocations } from "../../queries/locations";
import { useCallVisit } from "../../queries/visits";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../queries/clients";
import { useTouchpadButtons } from "../../queries/touchpad";

export default function Waitlist({ currentVisit }) {
  const [currentLocation, setCurrentLocation] = useAtom(currentLocationAtom);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visitId, setVisitId] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [onlyMe, setOnlyMe] = useAtom(onlyMeAtom);
  const [hideCalled, setHideCalled] = useAtom(hideCalledAtom);

  const navigate = useNavigate();
  const { data: userChiropractor } = useUserChiropractor();
  const { data: chiropractors, isLoading: chiropractorsLoading } =
    useAllChiropractors();
  const { data: locations } = usePublicLocations();
  const { data: visits, isLoading: visitsLoading } = useWaitlistVisits();
  const { data: client } = useClient(
    !!visits
      ? visits.find((visit) => visit.id === visitId)?.relationships.client.data
          .id
      : null
  );
  const { mutate: callVisit } = useCallVisit(userChiropractor?.id);
  const { mutate: uncallVisit } = useCallVisit(null);
  const { mutate: cancelVisit } = useCancelVisit();
  useTouchpadButtons(); // preload touchpad buttons in background

  if (visitsLoading || chiropractorsLoading) return <Loading size={120} />;

  // assign chiropractor(s) to visit objects
  visits.forEach((visit) => {
    visit.chiropractor = chiropractors.find(
      (chiro) => chiro.id === visit.relationships.chiropractor.data?.id
    );
    visit.prefChiropractor = chiropractors.find(
      (chiro) => chiro.id === visit.relationships.prefChiropractor.data?.id
    );
  });

  // if "Only me" switch is set
  let filteredVisits = onlyMe
    ? visits.filter(
        (visit) =>
          // keep if visit called by me
          visit.relationships.chiropractor.data?.id === userChiropractor?.id ||
          // keep if chose me
          visit.relationships.prefChiropractor.data?.id ===
            userChiropractor?.id ||
          // keep if uncalled and chose "any"
          (!visit.attributes.timeCalled &&
            !visit.relationships.prefChiropractor.data?.id)
      )
    : visits;

  // if "Hide called" switch is set
  filteredVisits = hideCalled
    ? filteredVisits.filter((visit) => visit.attributes.timeCalled === null)
    : filteredVisits;

  const openMenu = (event, newVisitId) => {
    setVisitId(newVisitId);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const reorder = (visitList, startIndex, endIndex) => {
    const orderValues = visitList.map((visit) => ({ ...visit }.order));

    while (visits[endIndex].timeCalled?.length > 0) endIndex++;

    const [removed] = visitList.splice(startIndex, 1);
    visitList.splice(endIndex, 0, removed);

    for (let i = 0; i < visitList.length; i++) {
      visitList[i].order = orderValues[i];
    }

    return visitList;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newVisits = reorder(
      visits,
      result.source.index,
      result.destination.index
    );
    axios.post("/api/ehr/reorder-waitlist", newVisits);
  };

  const handleLocationChange = (event) => {
    setCurrentLocation(locations.find((loc) => loc.id === event.target.value));
  };

  const handleChangeOnlyMe = (event) => {
    setOnlyMe(event.target.checked);
  };

  const handleChangeHideCalled = (event) => {
    setHideCalled(event.target.checked);
  };

  return (
    <React.Fragment>
      <div style={{ padding: 4 }}>
        <Select
          labelId="location-select-label"
          value={currentLocation.id}
          onChange={handleLocationChange}
        >
          {locations.map((loc) => (
            <MenuItem value={loc.id} key={loc.id}>
              {loc.attributes.name}
            </MenuItem>
          ))}
        </Select>

        <div style={{ float: "right" }}>
          <FormControlLabel
            control={
              <Switch
                checked={onlyMe}
                onChange={handleChangeOnlyMe}
                name="onlyMe"
              />
            }
            label="Only me"
          />
        </div>

        <div style={{ float: "right" }}>
          <FormControlLabel
            control={
              <Switch
                checked={hideCalled}
                onChange={handleChangeHideCalled}
                name="hideCalled"
              />
            }
            label="Hide called"
          />
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <List ref={provided.innerRef}>
              {filteredVisits.map((visit, index) => (
                <WaitlistItem
                  key={visit.id}
                  visit={visit}
                  nextVisit={filteredVisits[index + 1]}
                  index={index}
                  selected={currentVisit?.id === visit.id}
                  openMenu={(e) => openMenu(e, visit.id)}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => navigate(`/visit/${visitId}`)}>
          <ListItemIcon>
            <Visibility color="primary" />
          </ListItemIcon>
          Preview
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (
              !visits.find((visit) => visit.id === visitId)?.attributes
                .timeCalled
            ) {
              callVisit(visitId);
              navigate(`/visit/${visitId}`);
            } else {
              uncallVisit(visitId);
            }
          }}
        >
          <ListItemIcon>
            {!visits.find((visit) => visit.id === visitId)?.attributes
              .timeCalled && <PersonAdd color="primary" />}
            {!!visits.find((visit) => visit.id === visitId)?.attributes
              .timeCalled && <PersonAddDisabled color="primary" />}
          </ListItemIcon>
          {!visits.find((visit) => visit.id === visitId)?.attributes.timeCalled
            ? "Call"
            : "Uncall"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setConfirmOpen(true);
          }}
        >
          <ListItemIcon>
            <Cancel color="error" />
          </ListItemIcon>
          Cancel visit
        </MenuItem>
      </Menu>
      <ConfirmDialog
        open={confirmOpen}
        onConfirm={async () => {
          await setConfirmOpen(false);
          cancelVisit(visitId);
          if (currentVisit?.id === visitId) navigate("/waitlist");
        }}
        setOpen={setConfirmOpen}
        confirmText="Delete"
      >
        <div>
          Delete visit for{" "}
          {client &&
            (client.attributes.nickname?.trim() ||
              client.attributes.firstName) +
              " " +
              client.attributes.lastName}
          ?
        </div>
      </ConfirmDialog>
    </React.Fragment>
  );
}

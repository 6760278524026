import { getData, postData, patchData } from ".";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { tokenAtom, alertAtom, permissionsAtom } from "../store";

export const useUser = () => {
  const [token, setToken] = useAtom(tokenAtom);
  const setPermissions = useSetAtom(permissionsAtom);
  const queryClient = useQueryClient();

  return useQuery(
    ["users", "me"],
    async () => {
      const { data: user, included: roles } = await getData(
        "/api/users/me",
        token
      );
      queryClient.setQueryData(["roles", "me"], roles);
      const permissions =
        roles?.flatMap((role) => role.attributes?.permissions || []) ?? [];
      setPermissions([...new Set(permissions)]);
      return user;
    },
    {
      enabled: !!token,
      onError: (error) => {
        if (error.status === 401) {
          // log user out if unauthorized (invalid/expired/revoked token)
          setToken(null);
          setPermissions([]);
          queryClient.removeQueries();
        }
      },
    }
  );
};

export const useUsers = () => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["users"],
    async () => {
      const { data: users } = await getData("/api/users", token);
      return users;
    },
    { enabled: !!token }
  );
};

export const useCreateUser = () => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();
  const setAlert = useSetAtom(alertAtom);

  return useMutation(
    (user) => {
      return postData(`/api/users`, token, { data: user });
    },
    {
      enabled: !!token,
      onSettled: async ({ data: user, errors }) => {
        // handle errors
        if (!!errors?.[0]) {
          setAlert({ title: errors[0], content: errors[0].detail });
          return;
        } else if (!user?.id) {
          setAlert({ content: "Error creating user." });
          return;
        }

        // add new user to users list
        await queryClient.cancelQueries(["users"]);
        const previousUsers = queryClient.getQueryData(["users"]);
        !!previousUsers &&
          queryClient.setQueryData(["users"], [...previousUsers, user]);
      },
    }
  );
};

export const useUpdateUser = () => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();
  const setAlert = useSetAtom(alertAtom);

  return useMutation(
    (user) => {
      return patchData(`/api/users/${user?.id}`, token, { data: user });
    },
    {
      enabled: !!token,
      onSettled: async ({ data: updatedUser, errors }) => {
        // handle errors
        if (!!errors?.[0]) {
          setAlert({ title: errors[0], content: errors[0].detail });
          return;
        } else if (!updatedUser?.id) {
          setAlert({ content: "Error updating user." });
          return;
        }

        // update user in users list
        await queryClient.cancelQueries(["users"]);
        const previousUsers = queryClient.getQueryData(["users"]);
        !!previousUsers &&
          queryClient.setQueryData(["users"], (users) => {
            const userIndex = users.findIndex((u) => u.id === updatedUser.id);
            users[userIndex] = updatedUser;
            return users;
          });
      },
    }
  );
};

import { getData } from ".";
import { useQuery } from "react-query";
import { useAtomValue } from "jotai/utils";
import { tokenAtom } from "../store";

export const useRandomWelcomeMessage = (category) => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["randomWelcomeMessage", category],
    async () => {
      const { data: message } = await getData(
        `/api/welcome-messages/random?filter[category]=${category}`,
        token
      );
      return message;
    },
    { enabled: token != null }
  );
};

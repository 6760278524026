import { useEffect } from "react";
import { Container, Typography, Avatar, Button } from "@mui/material";
import { useSetAtom } from "jotai";
import { useParams, useNavigate } from "react-router-dom";
import { useClient } from "../../queries/clients";
import { currentClientAtom } from "../../store";

export default function ClientProfile() {
  const params = useParams();
  const navigate = useNavigate();
  const {
    isLoading: clientLoading,
    isError,
    error,
    data: client,
  } = useClient(params.clientId);

  const setCurrentClient = useSetAtom(currentClientAtom);
  useEffect(() => {
    if (!!client) {
      setCurrentClient(client);
    }
    return () => {
      setCurrentClient(null);
    };
  }, [client, setCurrentClient]);

  if (clientLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  if (!client) return <div>Client file not found.</div>;

  return (
    <Container maxWidth={false} style={{ textAlign: "center", padding: 16 }}>
      <Typography variant="h6">
        {client.attributes.firstName?.trim() +
          " " +
          (client.attributes.nickname?.trim() &&
          client.attributes.nickname?.trim() !== client.attributes.firstName
            ? '"' + client.attributes.nickname?.trim() + '"'
            : "") +
          " " +
          client.attributes.lastName}
      </Typography>
      <Avatar
        src={client?.attributes.photoUrl ?? "//:0"}
        style={{
          width: 128,
          height: 128,
          cursor: "pointer",
          margin: "auto",
        }}
      >
        {(client?.attributes.nickname?.charAt(0) ||
          client?.attributes.firstName?.charAt(0)) +
          client?.attributes.lastName?.charAt(0)}
      </Avatar>

      <Button
        onClick={() => navigate(`/clients/${client.id}/ehr`)}
        sx={{ m: 2 }}
      >
        EHR
      </Button>

      <Button
        component="a"
        href={`https://cnschiro.com/files/client-forms/${client.id}`}
        target="cns-1"
        sx={{ m: 2 }}
      >
        Forms
      </Button>

      <Button
        component="a"
        href={`https://cnschiro.com/files/client-files/${client.id}`}
        target="cns-1"
        sx={{ m: 2 }}
      >
        Files
      </Button>

      <Button
        component="a"
        href={`https://cnschiro.com/communication/log/${client.id}`}
        target="cns-1"
        sx={{ m: 2 }}
      >
        Communication Log
      </Button>
    </Container>
  );
}

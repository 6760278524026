import React, { useState, useEffect, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import {
  MoreVert,
  Check,
  Edit,
  OpenInNew,
  VisibilityOff,
  Delete,
  WarningAmberRounded,
} from "@mui/icons-material";
import ConfirmDialog from "../ConfirmDialog";
import {
  useUserChiropractor,
  useAllChiropractors,
} from "../../queries/chiropractors";
import {
  useStickyNotes,
  useCreateStickyNote,
  useUpdateStickyNote,
} from "../../queries/stickyNotes";
import Loading from "../Loading";

export default function StickyNotes({ clientId }) {
  const theme = useTheme();
  const styles = {
    note: {
      margin: theme.spacing(1),
      padding: theme.spacing(0.5),
      "& div": {
        overflow: "hidden",
      },
      "& .MuiIconButton-root": {
        float: "right",
        padding: theme.spacing(1),
      },
      "& .iconList": {
        float: "left",
      },
      "& .iconList svg": {
        fontSize: "inherit",
        margin: "0px 2px",
      },
    },
    importantNote: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
    },
    privateNoteText: {
      color: "transparent",
      textShadow: "0 0 8px rgba(0,0,0,0.8)",
      "&:hover": {
        color: "inherit",
        textShadow: "none",
      },
    },
  };

  const { data: userChiropractor, isLoading: chiropractorLoading } =
    useUserChiropractor();
  const { data: chiropractors, isLoading: chiropractorsLoading } =
    useAllChiropractors();
  const { data: stickyNotes, isLoading: stickyNotesLoading } =
    useStickyNotes(clientId);
  const { mutate: createStickyNote } = useCreateStickyNote(clientId);
  const { mutate: updateStickyNote } = useUpdateStickyNote(clientId);
  const [anchorEl, setAnchorEl] = useState(null);
  const [noteIndex, setNoteIndex] = useState(null);
  const [draft, setDraft] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [popupNotes, setPopupNotes] = useState(null);

  useEffect(() => {
    if (typeof stickyNotes != "undefined" && popupNotes === null) {
      setPopupNotes(
        (stickyNotes ? [...stickyNotes] : []).filter((n) => n.attributes?.popup)
      );
    }
  }, [stickyNotes, popupNotes]);

  const openMenu = (event, index) => {
    setNoteIndex(index);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const closeMenu = (event) => {
    setNoteIndex(null);
    setAnchorEl(null);
    event.stopPropagation();
  };

  const toggleProperty = async (property) => {
    setAnchorEl(null);
    await updateStickyNote({
      ...stickyNotes[noteIndex],
      attributes: {
        ...stickyNotes[noteIndex].attributes,
        [property]: !stickyNotes[noteIndex]["attributes"][property],
      },
    });
    setNoteIndex(null);
  };

  const editNote = () => {
    setAnchorEl(null);
    setDraft(stickyNotes[noteIndex]);
  };

  const handleDraftChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setDraft((prevDraft) => ({
      ...prevDraft,
      attributes: { ...prevDraft.attributes, [name]: value },
    }));
  };

  if (
    !clientId ||
    stickyNotesLoading ||
    chiropractorsLoading ||
    chiropractorLoading
  )
    return <Loading />;

  return (
    <Fragment>
      <Box flex="0 0 auto" style={{ textAlign: "center" }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            setDraft({
              type: "stickyNotes",
              relationships: {
                client: { data: { id: clientId, type: "clients" } },
                chiropractor: {
                  data: { id: userChiropractor.id, type: "chiropractors" },
                },
              },
              attributes: {
                message: "",
                urgent: false,
                private: false,
                popup: false,
                void: false,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              },
            })
          }
        >
          New Sticky Note
        </Button>
      </Box>
      <Box flex="1 1 auto" style={{ overflowY: "auto" }}>
        {stickyNotes
          .sort((a, b) =>
            a.attributes.createdAt > b.attributes.createdAt ? 1 : -1
          )
          .sort((a, b) => (a.attributes.urgent < b.attributes.urgent ? 1 : -1))
          .filter((note) => note.attributes.void === false)
          .map((stickyNote, index) => {
            const style = { ...styles.note };
            if (stickyNote.attributes.urgent)
              Object.assign(style, styles.importantNote);
            if (stickyNote.attributes.private)
              Object.assign(style, styles.privateNote);
            return (
              <Paper elevation={3} sx={style} key={"sticky-note-" + index}>
                <div>
                  <IconButton onClick={(e) => openMenu(e, index)}>
                    <MoreVert />
                  </IconButton>
                  <Typography
                    variant="body1"
                    sx={
                      stickyNote.attributes.private
                        ? styles.privateNoteText
                        : {}
                    }
                  >
                    {stickyNote.attributes.message}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="caption"
                    display="block"
                    align="right"
                    noWrap
                  >
                    <div className="iconList">
                      {stickyNote.attributes.urgent && <WarningAmberRounded />}
                      {stickyNote.attributes.popup && (
                        <OpenInNew fontSize="inherit" />
                      )}
                      {stickyNote.attributes.private && (
                        <VisibilityOff fontSize="inherit" />
                      )}
                    </div>
                    <Tooltip
                      placement="right"
                      title={
                        <Typography variant="caption">
                          Created:{" "}
                          {new Date(
                            stickyNote.attributes.createdAt
                          ).toLocaleString()}
                          <br />
                          Updated:{" "}
                          {new Date(
                            stickyNote.attributes.updatedAt
                          ).toLocaleString()}
                        </Typography>
                      }
                    >
                      <span>
                        --
                        {new Date(
                          stickyNote.attributes.createdAt
                        ).toLocaleString(
                          {},
                          {
                            month: "numeric",
                            day: "numeric",
                            year: "numeric",
                          }
                        ) + " "}
                        (
                        {!!stickyNote.relationships?.chiropractor?.data?.id &&
                          chiropractors.find(
                            (chiro) =>
                              chiro.id ===
                              stickyNote.relationships.chiropractor.data.id
                          )?.attributes.displayName}
                        )
                      </span>
                    </Tooltip>
                  </Typography>
                </div>
              </Paper>
            );
          })}
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={editNote}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Edit Note
        </MenuItem>
        <MenuItem onClick={() => toggleProperty("urgent")}>
          <ListItemIcon>
            <WarningAmberRounded />
          </ListItemIcon>
          Important
          <ListItemIcon
            style={{
              paddingLeft: 8,
              visibility:
                noteIndex !== null && stickyNotes[noteIndex].attributes.urgent
                  ? "visible"
                  : "hidden",
            }}
          >
            <Check />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => toggleProperty("popup")}>
          <ListItemIcon>
            <OpenInNew />
          </ListItemIcon>
          Popup
          <ListItemIcon
            style={{
              paddingLeft: 8,
              visibility:
                noteIndex !== null && stickyNotes[noteIndex].attributes.popup
                  ? "visible"
                  : "hidden",
            }}
          >
            <Check />
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={() => toggleProperty("private")}>
          <ListItemIcon>
            <VisibilityOff />
          </ListItemIcon>
          Private
          <ListItemIcon
            style={{
              paddingLeft: 8,
              visibility:
                noteIndex !== null && stickyNotes[noteIndex].attributes.private
                  ? "visible"
                  : "hidden",
            }}
          >
            <Check />
          </ListItemIcon>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setConfirmOpen(true);
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>

      <ConfirmDialog
        open={confirmOpen}
        onConfirm={() => toggleProperty("void")}
        setOpen={setConfirmOpen}
        confirmText="Delete"
      >
        Are you sure you want to delete this note?
      </ConfirmDialog>

      <Dialog open={typeof draft === "object"} onClose={() => setDraft(false)}>
        <DialogTitle>
          {draft?.id ? "Edit Sticky Note" : "New Sticky Note"}
        </DialogTitle>
        <DialogContent>
          <textarea
            placeholder="Enter note..."
            name="message"
            value={draft?.attributes?.message}
            onChange={handleDraftChange}
            rows={5}
            autoFocus
          />
          <div></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDraft(false)}>Cancel</Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={async () => {
              if (draft?.id) await updateStickyNote(draft);
              else if (draft) await createStickyNote(draft);
              setDraft(false); // closes dialog after save
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {popupNotes
        ?.filter((_popupNote, index) => index === 0) // only render one at a time
        .map((popupNote, index) => {
          return (
            <Dialog
              open={popupNotes[index] === popupNote}
              key={"popup-" + popupNote.id}
            >
              <DialogTitle>Popup Note</DialogTitle>
              <DialogContent>
                <Typography variant="body1">
                  {popupNote.attributes.message}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setPopupNotes(popupNotes.slice(1));
                  }}
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          );
        })}
    </Fragment>
  );
}

import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useAppSettings } from "../queries/settings";

function BirthdayConfetti({ birthDate }) {
  const [confetti, setConfetti] = useState(false);
  const { data: appSettings } = useAppSettings();

  useEffect(() => {
    if (!birthDate) return;

    const oneDay = 1000 * 60 * 60 * 24;
    const timezone = new Date().getTimezoneOffset() * 60 * 1000;
    const today =
      new Date().getTime() -
      timezone -
      ((new Date().getTime() + timezone) % oneDay);
    const year = new Date().getFullYear();

    const birthdays = [
      new Date(birthDate).setFullYear(year - 1) + timezone,
      new Date(birthDate).setFullYear(year) + timezone,
      new Date(birthDate).setFullYear(year + 1) + timezone,
    ];

    const birthdayOffsets = birthdays.map((birthday) => {
      return Math.abs(today - birthday);
    });
    const birthdayIndex = birthdayOffsets.indexOf(Math.min(...birthdayOffsets));
    const closestBirthday = birthdays[birthdayIndex];
    const daysUntilBirthday = (closestBirthday - today) / oneDay;

    setConfetti(
      daysUntilBirthday >= appSettings.flagBirthdayAfter * -1 &&
        daysUntilBirthday <= appSettings.flagBirthdayBefore
    );
  }, [birthDate, appSettings]);

  // Render your confetti component here. For now, it's just a div showing 'Confetti!'
  return (
    confetti && (
      <Confetti numberOfPieces={800} tweenDuration={20000} recycle={false} />
    )
  );
}

export default BirthdayConfetti;

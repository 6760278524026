import { getData } from ".";
import { useQuery } from "react-query";
import { useAtomValue } from "jotai/utils";
import { tokenAtom } from "../store";

export const usePublicLocations = () => {
  return useQuery(["publicLocations"], async () => {
    const { data: locations } = await getData(`/api/locations/public`);
    return locations;
  });
};

export const useWalkInChiropractors = (locationId) => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["walkInChiropractors", locationId],
    async () => {
      const { data: chiropractors } = await getData(
        `/api/locations/${locationId}/walk-in-chiropractors`,
        token
      );
      return chiropractors;
    },
    {
      enabled: !!token && !!locationId,
      staleTime: 10 * 1000, // 10 seconds
    }
  );
};

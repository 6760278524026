import React from "react";
import { Box } from "@mui/material";
import { ArrowBack, Undo, Redo, ClearAll, Send } from "@mui/icons-material";
import Loading from "../Loading";
//import TouchpadEditDialog from "./TouchpadEditDialog";
import TouchpadButton from "./TouchpadButton";
import { useTouchpadButtons } from "../../queries/touchpad";
import { useAppSettings } from "../../queries/settings";

const Touchpad = ({
  noteRef,
  undo,
  redo,
  canUndo,
  canRedo,
  addNote,
  completeVisit,
}) => {
  const [buttonSize, setButtonSize] = React.useState(0);
  const [touchpadId, setTouchPadId] = React.useState("0");

  //const [editButton, setEditButton] = React.useState({});
  //const [width, setWidth] = React.useState(0);
  //const [height, setHeight] = React.useState(0);

  const { data: appSettings, isLoading: appSettingsLoading } = useAppSettings();
  const { data: buttons, isLoading: buttonsLoading } = useTouchpadButtons();

  const boxRef = React.useCallback(
    (node) => {
      if (node !== null) {
        function updateButtonSize() {
          const boxHeight = node.getBoundingClientRect().height;
          const boxWidth = node.getBoundingClientRect().width;
          const newSize = Math.min(
            boxWidth / parseInt(appSettings.touchpadColumns),
            boxHeight / (parseInt(appSettings.touchpadRows) + 1),
            appSettings.maxTouchpadButtonSize || 96
          );
          setButtonSize(newSize);
        }
        //console.log("assigning event listener (should only run once)");
        window.addEventListener("resize", updateButtonSize);
        updateButtonSize();
      }
    },
    [appSettings]
  );

  /*const handleButtonRightClick = (button) => {
    setEditButton(button);
  };*/

  const smartAppend = (oldNote, text, newLine) => {
    let newNote = oldNote;
    // add whitespace if needed
    if (
      oldNote !== "" &&
      text !== "" &&
      !newLine &&
      !oldNote.match(/\s$/g) &&
      !text.match(/^[^a-z|^A-Z|^0-9]/g)
    ) {
      newNote += " ";
    } else if (oldNote !== "" && newLine) {
      newNote += "\n";
    }

    // capitalize text if after period, newLine, or is beginning of note
    // change ". " to include period followed by any whitespace ********
    newNote +=
      newNote === "" || newNote.match(/\.\s+$/) || newNote.endsWith("\n")
        ? text.charAt(0).toUpperCase() + text.slice(1)
        : text;

    return newNote;
  };

  const addText = (text, newLine) => {
    const prevNote = noteRef.current;
    const textBefore = prevNote.value.substring(0, prevNote.selectionStart);
    const textAfter = prevNote.value.substring(prevNote.selectionEnd);

    const newNote = smartAppend(
      smartAppend(textBefore, text, newLine),
      textAfter,
      false
    );

    const newSelection =
      prevNote.selectionEnd + newNote.length - prevNote.value.length;
    noteRef.current.setSelectionRange(newSelection, newSelection);
    noteRef.current.focus();

    addNote(newNote);
  };

  const clearNote = () => {
    noteRef.current.setSelectionRange(0, 0);
    noteRef.current.focus();
    addNote("");
  };

  const goBack = () => {
    const parentTouchpad = buttons.find((b) => b.id === touchpadId).attributes
      .touchpadId;
    setTouchPadId(parentTouchpad);
  };

  if (buttonsLoading || appSettingsLoading) return <Loading />;

  return (
    <Box
      display="flex"
      flexDirection="column"
      ref={boxRef}
      style={{ height: "100%", overflow: "hidden" }}
      onFocus={() => noteRef.current.focus()}
    >
      <Box key="row-header" display="flex" flexDirection="row">
        <TouchpadButton
          size={buttonSize}
          buttonType="touchpad"
          onClick={goBack}
          disabled={touchpadId === "0"}
        >
          <ArrowBack />
          Back
        </TouchpadButton>
        <TouchpadButton
          size={buttonSize}
          buttonType="function"
          onClick={clearNote}
          disabled={noteRef.current?.value.length === 0}
        >
          <ClearAll />
          Clear
        </TouchpadButton>
        <TouchpadButton
          size={buttonSize}
          buttonType="function"
          onClick={undo}
          disabled={!canUndo}
        >
          <Undo />
          Undo
        </TouchpadButton>
        <TouchpadButton
          size={buttonSize}
          buttonType="function"
          onClick={redo}
          disabled={!canRedo}
        >
          <Redo />
          Redo
        </TouchpadButton>
        <TouchpadButton
          size={buttonSize}
          onClick={() => addText("left", false)}
        >
          Left
        </TouchpadButton>
        <TouchpadButton
          size={buttonSize}
          onClick={() => addText("right", false)}
        >
          Right
        </TouchpadButton>
        <TouchpadButton
          size={buttonSize}
          onClick={() => addText("bilateral", false)}
        >
          Bilateral
        </TouchpadButton>
        {completeVisit !== false && (
          <TouchpadButton
            size={buttonSize}
            buttonType="function"
            onClick={completeVisit}
          >
            <Send />
            Complete Visit
          </TouchpadButton>
        )}
      </Box>
      {Array.from({ length: appSettings.touchpadRows }).map((_x, rowIndex) => {
        return (
          <Box key={"row-" + rowIndex} display="flex" flexDirection="row">
            {Array.from({ length: appSettings.touchpadColumns }).map(
              (_y, colIndex) => {
                const button = buttons.find(
                  (b) =>
                    b.attributes.touchpadId === touchpadId &&
                    b.attributes.row === rowIndex &&
                    b.attributes.column === colIndex
                ) || {
                  row: rowIndex,
                  column: colIndex,
                  touchpadId: touchpadId,
                };

                return (
                  <TouchpadButton
                    key={"button-" + rowIndex + "-" + colIndex}
                    size={buttonSize}
                    options={button.attributes?.options}
                    buttonType={button.attributes?.isTouchpad ? "touchpad" : ""}
                    onClick={() =>
                      button.attributes?.isTouchpad
                        ? setTouchPadId(button.id)
                        : addText(
                            button.attributes?.value || "",
                            button.attributes?.newLine || false
                          )
                    }
                    onOptionClick={(option) => {
                      addText(option.value || "", option.newLine || false);
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      //handleButtonRightClick(button);
                    }}
                  >
                    {button.attributes?.caption}
                  </TouchpadButton>
                );
              }
            )}
          </Box>
        );
      })}

      {/*<TouchpadEditDialog
        button={editButton}
        onClose={() => {
          setEditButton({});
          refreshButtons();
        }}
      />*/}
    </Box>
  );
};

//const MemoizedTouchpad = React.memo(Touchpad);
//export default MemoizedTouchpad;
export default Touchpad;

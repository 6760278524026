import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, Button, Snackbar, Typography } from "@mui/material";
import { useAppSettings } from "../queries/settings";
import { useAtomValue } from "jotai";
import { permissionsAtom } from "../store";
import { useUser } from "../queries/users";

export default function KioskLayout() {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const { data: appSettings } = useAppSettings();
  const { data: user } = useUser();
  const permissions = useAtomValue(permissionsAtom);

  useEffect(() => {
    const timer = setInterval(() => {
      if (window.updateAvailable) {
        setNewVersionAvailable(true);
      }
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  if (!user.attributes?.isSuper && !permissions.includes("kiosk"))
    return (
      <Typography variant="h2" align="center" m={4}>
        Unauthorized
      </Typography>
    );

  return (
    <Box style={{ textAlign: "center", margin: 16 }}>
      <Box style={{ display: "inline-block", margin: "auto" }}>
        <img src={appSettings?.logo} alt="logo" style={{ maxWidth: 256 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ margin: 8 }}></div>
          <Outlet />
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            open={newVersionAvailable}
            message="An updated version of the kiosk is available."
            action={
              <Button color="primary" size="small" onClick={refreshPage}>
                Update
              </Button>
            }
          />
        </div>
      </Box>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Avatar,
  Box,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Paper,
  Typography,
  ListItemIcon,
  CircularProgress,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  PersonAdd,
} from "@mui/icons-material";
import { useClientSearch, useRefreshClients } from "../queries/clients";
import { useNavigate } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";
import { useQueryClient } from "react-query";

export default function HeaderClientSearch() {
  const theme = useTheme();
  const styles = {
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIconWrapper: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    clearIcon: {
      position: "absolute",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      right: 0,
      zIndex: 2,
    },
    inputBase: {
      color: "inherit",
      "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
          width: "32ch",
        },
      },
    },
    searchResults: {
      position: "absolute",
      width: "100%",
    },
    secondaryText: {
      color: theme.palette.text.secondary,
      fontSize: "10pt",
    },
  };

  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const queryClient = useQueryClient();
  const { mutate: refreshClients, isLoading: refreshingClients } =
    useRefreshClients();
  const { data: clients, isFetching: clientsFetching } =
    useClientSearch(debouncedSearchTerm);

  useEffect(() => {
    const debouncedSearch = _.debounce(setDebouncedSearchTerm, 200);
    debouncedSearch(searchTerm);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm]);

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRefreshClients = () => {
    refreshClients(null, {
      onSuccess: () => {
        // retry search after successfully refreshing client database
        queryClient.resetQueries({
          queryKey: ["clients", "search"],
        });
      },
    });
  };

  const handleKeyDown = (e) => {
    if (clients) {
      switch (e.key) {
        case "ArrowDown":
          if (selectedIndex === null || selectedIndex + 1 >= clients.length)
            setSelectedIndex(0);
          else setSelectedIndex(selectedIndex + 1);
          break;
        case "ArrowUp":
          if (selectedIndex === null || selectedIndex === 0)
            setSelectedIndex(clients.length - 1);
          else setSelectedIndex(selectedIndex - 1);
          break;
        case "Enter":
          selectClient(selectedIndex);
          break;
        case "Escape":
          setSearchTerm("");
          //setClients(null);
          break;
        default:
          break;
      }
    }
  };

  const selectClient = (index) => {
    navigate("/clients/" + clients[index].id);
    setSearchTerm("");
  };

  return (
    <Box sx={styles.search} onBlur={() => console.log("blurred")}>
      <Box sx={styles.searchIconWrapper}>
        {clientsFetching ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <SearchIcon />
        )}
      </Box>
      <Box sx={styles.clearIcon}>
        {searchTerm.length > 0 && (
          <IconButton onClick={() => setSearchTerm("")} color="inherit">
            <ClearIcon />
          </IconButton>
        )}
      </Box>
      <InputBase
        placeholder="Search name, phone, email..."
        sx={styles.inputBase}
        value={searchTerm}
        onChange={handleSearchTermChange}
        onKeyDown={handleKeyDown}
      />

      <Paper
        sx={styles.searchResults}
        style={{
          visibility:
            searchTerm.trim().length < 2 || clients === null
              ? "hidden"
              : "visible",
        }}
      >
        <List dense>
          {clients?.map((client, index) => (
            <ListItem
              key={index}
              button
              divider
              selected={index === selectedIndex}
              onClick={() => selectClient(index)}
            >
              <ListItemAvatar>
                <Avatar src={client.attributes.photoUrl}>
                  {(client.attributes.nickname?.charAt(0) ||
                    client.attributes.firstName?.charAt(0)) +
                    client.attributes.lastName?.charAt(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body1" color="textPrimary">
                    {client.attributes.firstName?.trim() +
                      " " +
                      (client.attributes.nickname?.trim() &&
                      client.attributes.nickname?.trim() !==
                        client.attributes.firstName
                        ? '"' + client.attributes.nickname?.trim() + '"'
                        : "") +
                      " " +
                      client.attributes.lastName}
                  </Typography>
                }
                secondary={
                  <div>
                    <div>
                      <Typography variant="body2" color="textSecondary" noWrap>
                        {client.attributes.mobilePhone &&
                          "m: " +
                            client.attributes.mobilePhone.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "($1) $2-$3"
                            )}
                        {client.attributes.mobilePhone &&
                          client.attributes.homePhone &&
                          ", "}
                        {client.attributes.homePhone &&
                          "h: " +
                            client.attributes.homePhone.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "($1) $2-$3"
                            )}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="body2" color="textSecondary" noWrap>
                        {client.attributes.email}
                      </Typography>
                    </div>
                  </div>
                }
              />
            </ListItem>
          ))}
          {clients?.length === 0 && (
            <ListItem
              divider
              button
              onClick={handleRefreshClients}
              disabled={refreshingClients}
            >
              <ListItemIcon>
                {refreshingClients ? (
                  <CircularProgress />
                ) : (
                  <CachedIcon sx={{ fontSize: "2.4rem" }} />
                )}
              </ListItemIcon>
              <ListItemText
                primary="No practice members found."
                secondary={
                  refreshingClients
                    ? "Refreshing data..."
                    : "Refresh MINDBODY data and retry?"
                }
              />
            </ListItem>
          )}
          <ListItem button disabled>
            <ListItemAvatar>
              <PersonAdd sx={{ fontSize: "2.4rem" }} />
            </ListItemAvatar>
            <ListItemText
              primary="Add New Practice Member"
              secondary="Coming soon..."
            />
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
}

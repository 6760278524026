import { getData } from ".";
import { useQuery } from "react-query";
import { useAtomValue } from "jotai/utils";
import { tokenAtom } from "../store";

export const useTouchpadButtons = () => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["touchpadButtons"],
    async () => {
      const { data: buttons } = await getData("/api/touchpad/buttons", token);
      return buttons;
    },
    { enabled: !!token }
  );
};

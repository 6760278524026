import { Button } from "@mui/material";
import { useRefreshSchedule } from "../../queries/appointments";
export default function Schedule() {
  const { mutate: refreshSchedule } = useRefreshSchedule();

  return (
    <div>
      <Button onClick={() => refreshSchedule(1)}>Refresh Schedule</Button>
    </div>
  );
}

import { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Toolbar,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import LaunchIcon from "@mui/icons-material/Launch";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import WaitlistIcon from "@mui/icons-material/ViewList";
import ReportsIcon from "@mui/icons-material/Assessment";

import { useUserChiropractor } from "../queries/chiropractors";
import HeaderClientSearch from "../components/HeaderClientSearch";
import { useAtom, useAtomValue } from "jotai";
import {
  currentClientAtom,
  currentLocationAtom,
  waitingForMindbodyAtom,
  permissionsAtom,
} from "../store";
import { useLogout } from "../queries/auth";
import AlertDialog from "../components/AlertDialog";
import {
  Assignment,
  Dvr,
  PermMedia,
  QuestionAnswer,
  Settings,
} from "@mui/icons-material";
import { useUser } from "../queries/users";

export default function BasicLayout() {
  const theme = useTheme();
  const styles = {
    grow: {
      flexGrow: 1,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  };
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useAtom(currentLocationAtom);
  const { data: chiropractor } = useUserChiropractor();
  const { mutate: logout } = useLogout();
  const { data: user } = useUser();
  const waitingForMindbody = useAtomValue(waitingForMindbodyAtom);
  const client = useAtomValue(currentClientAtom);
  const permissions = useAtomValue(permissionsAtom);
  //const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      if (window.updateAvailable) {
        setNewVersionAvailable(true);
      }
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar variant="dense" disableGutters>
          <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
            <MenuIcon />
          </IconButton>
          {!!client &&
            `${client.attributes.firstName?.trim()} ` +
              (!!client.attributes.nickname?.trim() &&
              client.attributes.nickname?.trim() !== client.attributes.firstName
                ? `"${client.attributes.nickname?.trim()}" `
                : "") +
              client.attributes.lastName}
          <HeaderClientSearch />
          <Box sx={styles.grow} />
          <Button color="inherit" onClick={() => setCurrentLocation(null)}>
            {currentLocation?.attributes.name}
          </Button>
          <Button
            color="inherit"
            //onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <Avatar
              src={chiropractor?.attributes?.photoUrl || "//:0"}
              sx={{ width: 24, height: 24, marginRight: 0.5 }}
            />
            {chiropractor?.attributes?.displayName}
            <ExpandMoreIcon />
          </Button>
        </Toolbar>
      </AppBar>
      <LinearProgress sx={{ opacity: waitingForMindbody ? 1 : 0 }} />
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <List style={{ width: "240px" }} onClick={() => setDrawerOpen(false)}>
            <ListItem component={Link} to="/" button key="Home">
              <ListItemIcon>
                <HomeIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>

            {(user?.attributes.isSuper || permissions?.includes("ehr")) && (
              <ListItem component={Link} to="/waitlist" button key="Waitlist">
                <ListItemIcon>
                  <WaitlistIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Waitlist" />
              </ListItem>
            )}
            <Divider />

            {!!client && (
              <>
                <ListItem
                  component={Link}
                  to={`/clients/${client.id}`}
                  button
                  key="Profile"
                >
                  <ListItemAvatar>
                    <Avatar
                      src={client?.attributes?.photoUrl || "//:0"}
                      sx={{ width: 24, height: 24 }}
                    >
                      {(client?.attributes?.nickname?.charAt(0) ||
                        client?.attributes?.firstName?.charAt(0)) +
                        client?.attributes?.lastName?.charAt(0)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      `${client.attributes.firstName?.trim()} ` +
                      (!!client.attributes.nickname?.trim() &&
                      client.attributes.nickname?.trim() !==
                        client.attributes.firstName
                        ? `"${client.attributes.nickname?.trim()}" `
                        : "") +
                      client.attributes.lastName
                    }
                  />
                </ListItem>

                <ListItem dense={true}>
                  <List disablePadding={true}>
                    <ListItem
                      component={Link}
                      to={`/clients/${client.id}/ehr`}
                      button
                      key="EHR"
                    >
                      <ListItemIcon>
                        <Dvr color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="EHR" />
                    </ListItem>

                    <ListItem
                      component="a"
                      href={`https://cnschiro.com/files/client-forms/${client.id}`}
                      target="cns-1"
                      button
                      key="Forms"
                    >
                      <ListItemIcon>
                        <Assignment color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Forms" />
                    </ListItem>

                    <ListItem
                      component="a"
                      href={`https://cnschiro.com/files/client-files/${client.id}`}
                      target="cns-1"
                      button
                      key="Files"
                    >
                      <ListItemIcon>
                        <PermMedia color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Files" />
                    </ListItem>

                    <ListItem
                      component="a"
                      href={`https://cnschiro.com/communication/log/${client.id}`}
                      target="cns-1"
                      button
                      key="Communication"
                    >
                      <ListItemIcon>
                        <QuestionAnswer color="primary" />
                      </ListItemIcon>
                      <ListItemText primary="Communication" />
                    </ListItem>
                  </List>
                </ListItem>

                <Divider />
              </>
            )}

            {(user.attributes?.isSuper || permissions.includes("reports")) && (
              <>
                <ListItem component={Link} to="/reports" button key="Reports">
                  <ListItemIcon>
                    <ReportsIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                </ListItem>
              </>
            )}

            {(user.attributes?.isSuper || permissions.includes("settings")) && (
              <>
                <ListItem component={Link} to="/settings" button key="Settings">
                  <ListItemIcon>
                    <Settings color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem>
              </>
            )}

            <Divider />

            {(user.attributes?.isSuper || permissions.includes("kiosk")) && (
              <ListItem component={Link} to="/kiosk" button key="Kiosk">
                <ListItemIcon>
                  <LaunchIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Launch Kiosk" />
              </ListItem>
            )}

            <ListItem button key="Logout" onClick={logout}>
              <ListItemIcon>
                <LogoutIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>

          <Box mt="auto">
            <Typography
              m={2}
              align="center"
              variant="subtitle2"
            >{`v${process.env.REACT_APP_VERSION}`}</Typography>
          </Box>
        </Box>
      </Drawer>
      <ErrorBoundary>
        <Outlet />
        <Snackbar
          open={newVersionAvailable}
          message="An updated version of CNS is available."
          action={
            <Button color="primary" size="small" onClick={refreshPage}>
              Update
            </Button>
          }
        />
      </ErrorBoundary>
      <AlertDialog />
    </>
  );
}

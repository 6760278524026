import { getData } from ".";
import { useQuery, useQueryClient } from "react-query";
import { useAtomValue } from "jotai/utils";
import { tokenAtom } from "../store";

export const useChiropractor = (chiroId) => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["chiropractors", chiroId],
    async () => {
      const { data: chiro } = await getData(
        `/api/chiropractors/${chiroId}`,
        token
      );
      return chiro;
    },
    { enabled: !!token && !!chiroId }
  );
};

export const useUserChiropractor = () => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["userChiropractor"],
    async () => {
      const { data: chiro } = await getData(
        `/api/chiropractors/user-chiropractor`,
        token
      );
      return chiro;
    },
    { enabled: !!token }
  );
};

export const useAllChiropractors = () => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();

  return useQuery(
    ["chiropractors"],
    async () => {
      const { data: chiros } = await getData(`/api/chiropractors`, token);
      chiros?.forEach((item) =>
        queryClient.setQueryData([item.type, item.id], item)
      );
      return chiros;
    },
    { enabled: !!token }
  );
};

export const usePublicChiropractors = () => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();

  return useQuery(
    ["chiropractors", { active: true }],
    async () => {
      const { data: chiros } = await getData(
        `/api/chiropractors/active`,
        token
      );
      chiros?.forEach((item) =>
        queryClient.setQueryData([item.type, item.id], item)
      );
      return chiros;
    },
    { enabled: !!token }
  );
};

import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#02A49D",
      light: "#9CF6F3",
      dark: "#05646A",
    },
    secondary: {
      main: "#99D547",
      light: "#E0F5B4",
      //contrastText: "#4F751A",
      //contrastText: "#3B5813",
      contrastText: "#FFFFFF",
    },
    accent: {
      main: "#B76EB8",
      //contrastText: "#311631",
      contrastText: "#FFFFFF",
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;

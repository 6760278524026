import { useEffect } from "react";
import { useSetAtom } from "jotai";
import { errorAtom } from "./store";

const RootErrorListener = () => {
  const setError = useSetAtom(errorAtom);

  useEffect(() => {
    const handleErrorEvent = (event) => {
      setError(event);
    };

    window.addEventListener("globalError", handleErrorEvent);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("globalError", handleErrorEvent);
    };
  }, [setError]);

  return null;
};

export default RootErrorListener;

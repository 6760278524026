import { getData, postData } from ".";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useAtomValue } from "jotai/utils";
import { tokenAtom } from "../store";

export const useClient = (clientId) => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["clients", clientId],
    async () => {
      const { data: clients } = await getData(
        `/api/clients/${clientId}`,
        token
      );
      return clients;
    },
    { enabled: !!token && !!clientId }
  );
};

export const useKioskClients = ({ phone, barcode }) => {
  const token = useAtomValue(tokenAtom);

  const filterString = barcode ? `[barcode]=${barcode}` : `[phone]=${phone}`;

  return useQuery(
    ["kioskClients", { phone, barcode }],
    async () => {
      const { data: clients } = await getData(
        `/api/clients?filter${filterString}&include=children,spouse,members`,
        token
      );
      return clients;
    },
    { enabled: !!token && (!!phone || !!barcode) }
  );
};

export const useClientSearch = (searchTerm) => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();

  return useQuery(
    ["clients", "search", { searchTerm }],
    async () => {
      if (searchTerm.trim().length < 2) return [];
      const { data: clients } = await getData(
        `/api/clients?filter[search]=${searchTerm}`,
        token
      );
      return clients;
    },
    {
      enabled: !!token && searchTerm?.length > 1,
      keepPreviousData: true,
      onSuccess: (clients) => {
        clients.forEach((client) => {
          queryClient.setQueryData(["clients", client.id], client);
        });
      },
    }
  );
};

export const useRefreshClients = () => {
  const token = useAtomValue(tokenAtom);

  return useMutation(
    () => {
      return postData(`/api/clients/refresh`, token);
    },
    {
      enabled: !!token,
      onSuccess: async (data) => {
        if (data.success) {
          console.log("Clients refreshed at " + new Date());
        } else {
          console.log("Clients NOT refreshed at " + new Date());
        }
      },
    }
  );
};

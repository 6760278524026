import {
  Autocomplete,
  Box,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import Loading from "../Loading";
import { useRoles, useUpdateRole } from "../../queries/roles";

export default function Permissions() {
  const { data: roles } = useRoles();
  const { mutate: updateRole } = useUpdateRole();

  const permissions = [
    { value: "ehr", label: "Access EHR" },
    { value: "ehr_complete_visit", label: "Complete visits in EHR" },
    { value: "ehr_edit_any_visit", label: "Edit ANY visit in EHR" },
    { value: "kiosk", label: "Launch Kiosk" },
    { value: "settings", label: "Access settings" },
  ];

  const changePermissionRoles = (permissionValue, rolesPermitted) => {
    roles.forEach((role) => {
      const isRolePermitted = rolesPermitted.some((rp) => rp.id === role.id);
      const permIndex = role.attributes.permissions?.indexOf(permissionValue);
      const newRole = { ...role };

      // if role is permitted and was not already set, add the permission
      if (isRolePermitted && permIndex === -1) {
        newRole.attributes.permissions.push(permissionValue);
        updateRole(newRole);

        // if role is not permitted and was previously set, remove the permission
      } else if (!isRolePermitted && permIndex > -1) {
        newRole.attributes.permissions.splice(permIndex, 1);
        updateRole(newRole);
      }
    });
  };

  if (!roles) return <Loading size={80} />;

  return (
    <Box>
      {permissions.map((permission, index) => (
        <Box key={permission.value} sx={{ mb: 2 }}>
          <Typography variant="h6">{permission.label}</Typography>
          <Autocomplete
            sx={{ width: "100%" }}
            multiple
            options={roles}
            disableCloseOnSelect
            getOptionLabel={(option) => option.attributes.name}
            renderOption={(props, optionRole, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {optionRole.attributes.name}
              </li>
            )}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            value={roles.filter((role) =>
              role.attributes?.permissions.includes(permission.value)
            )}
            onChange={(_e, permittedRoles) => {
              changePermissionRoles(permission.value, permittedRoles || []);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assign Roles"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
      ))}
    </Box>
  );
}

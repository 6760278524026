import { getData } from ".";
import { useQuery } from "react-query";
import { useAtomValue } from "jotai/utils";
import { tokenAtom } from "../store";

export const useHighlights = () => {
  const token = useAtomValue(tokenAtom);

  return useQuery("highlights", () => getData("/api/highlights", token), {
    enabled: token != null,
  });
};

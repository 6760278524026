import { getData, postData, patchData } from ".";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useAtomValue } from "jotai/utils";
import { tokenAtom } from "../store";

export const useStickyNotes = (clientId) => {
  const token = useAtomValue(tokenAtom);

  return useQuery(
    ["stickyNotes", { clientId }],
    async () => {
      const { data: stickyNotes } = await getData(
        `/api/clients/${clientId}/sticky-notes`,
        token
      );
      return stickyNotes;
    },
    { enabled: !!token && !!clientId }
  );
};

export const useCreateStickyNote = (clientId) => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();
  const queryKey = ["stickyNotes", { clientId }];

  return useMutation(
    (stickyNote) => postData(`/api/sticky-notes`, token, { data: stickyNote }),
    {
      enabled: !!token,
      onMutate: async (stickyNote) => {
        await queryClient.cancelQueries(queryKey);
        const previousStickyNotes = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, (stickyNotes) => {
          return [...stickyNotes, stickyNote]; // optimistically update query data
        });
        return { previousStickyNotes }; // passed to onError and onSettled context
      },
      onError: (error, _stickyNotes, context) => {
        console.log(error);
        queryClient.setQueryData(queryKey, context.previousStickyNotes); // reverse optimistic update
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );
};

export const useUpdateStickyNote = (clientId) => {
  const token = useAtomValue(tokenAtom);
  const queryClient = useQueryClient();
  const queryKey = ["stickyNotes", { clientId }];

  return useMutation(
    (stickyNote) =>
      patchData(`/api/sticky-notes/${stickyNote.id}`, token, {
        data: stickyNote,
      }),
    {
      enabled: !!token,
      onMutate: async (stickyNote) => {
        await queryClient.cancelQueries(queryKey);
        const previousStickyNotes = queryClient.getQueryData(queryKey);
        queryClient.setQueryData(queryKey, (stickyNotes) => {
          const noteIndex = stickyNotes.findIndex(
            (note) => note.id === stickyNote.id
          );
          stickyNotes[noteIndex] = stickyNote;
          return stickyNotes;
        });
        return { previousStickyNotes };
      },
      onError: (_error, _stickyNotes, context) => {
        queryClient.setQueryData(queryKey, context.previousStickyNotes);
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );
};
